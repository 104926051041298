import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Icons
import { MdCheck } from 'react-icons/md';
import addHomeWork from '~/assets/icons/add-home-work.svg';
import addHomeWorkIcon from '~/assets/icons/adicionar-casa-produto-imobiliario.svg';
import editIcon from '~/assets/icons/edit-produto-imobiliario.svg';

// Types
import { IHouseOnSelect, IHouseProps } from './types';

// Models
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';

// Partials
import { Box } from '../Box';

// Services
import { IoTrash } from 'react-icons/io5';
import api from '~/services/api';

export function House({projectID, realEstateProduct}: IHouseProps) {  
  const [housesOnLoading, setHousesOnLoading] = useState<boolean>(false);
  const [houses, setHouses] = useState<IMRealEstateProductsHouse[]>([]);  
  const [housesSelecteds, setHousesSelecteds] = useState<IHouseOnSelect[]>([]);  
  const [houseInDelete, setHouseInDelete] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!projectID) return;

    setHousesOnLoading(true);

    api
      .get<IMRealEstateProductsHouse[]>(
        `builders/real-estate-products/${projectID}/houses`
      )
      .then((resolver) => {
        if (resolver.status !== 200) {
          setHousesOnLoading(false);
          return;
        }

        setHouses(resolver.data);
        setHousesOnLoading(false);
        sessionStorage.setItem('REP@HOUSES', JSON.stringify(resolver.data))
      })
      .catch((rejects) => {
        setHousesOnLoading(false);
      });
  }, [projectID]);

  function handleToAddHouseStep() {
    try {
      history.push(`/produtos-imobiliario/novo-projeto/${projectID}/${5}`);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel iniciar o processo de cadastro de casas para o projeto',
        icon: 'warning',
      });
    }
  }

  function setSelectedHouses(projectID: number = -1, house: IMRealEstateProductsHouse | null = null) {
    try {
      if(!house) throw new Error('The house is missing');

      if(!projectID || projectID <= -1) throw new Error('The house is missing');


      if(housesSelecteds.find(d => d.projectID === projectID && d.house.id === house.id)) {
        setHousesSelecteds(h => h.filter(d =>  d.house.id !== house.id))
      }else{
        setHousesSelecteds(h => [
            {
              projectID,
              house
            },
            ...h        
            ]
        )
      }
    } catch (error) {
      // do anything
      console.table(error)
    }
  }

  async function handleDeleteHouses(startIn = 0){
    try {
      setHouseInDelete(true)
      if(startIn <= -1 || startIn > (housesSelecteds.length - 1)) throw new Error('The startIn is not valid');

      const HOUSE = housesSelecteds[startIn]

      const response = await api.delete<IMRealEstateProductsHouse>(`builders/real-estate-products/${(HOUSE.projectID)}/houses/${HOUSE.house.id}`);

      if (response.status !== 204) throw new Error('lkkn');

      if((startIn + 1) <= (housesSelecteds.length - 1)) {
        await handleDeleteHouses(startIn + 1);
        return;        
      }

      let HOUSES_CACHED  = [...houses]

      housesSelecteds.forEach(h => HOUSES_CACHED = HOUSES_CACHED.filter(j => j.id !== h.house.id))

      setHousesSelecteds([])
      setClearUIOfCheckeds()
      setHouses(HOUSES_CACHED)

      setHouseInDelete(false)

      Swal.fire({
        icon: 'success',
        text: 'Todas as casas foram excluidas'
      })
    } catch (error) {
      setHouseInDelete(false);
    }
  }

  function setClearUIOfCheckeds() {
    try {
      const ELEMENTS_CHECKED = document.querySelectorAll('.check-house-input')

      if(!ELEMENTS_CHECKED) throw new Error('');

      Array.from(ELEMENTS_CHECKED).forEach(element => {
        // @ts-ignore
        if(element.checked) {
          // @ts-ignore
          element.checked = false;
        }
      })
    } catch (error) {
      
    }
  }
  return (
    <div
      className="flex-1 w-full h-auto flex md:flex-row md:flex-nowrap  max-sm:flex-col
          gap-rep40 " 
    >
      <div
        className="max-sm:!w-screen md:w-full md:h-[39.312rem] max-sm:h-auto flex flex-col items-start justify-start gap-[2rem]
            max-sm:gap-[1.25rem] max-sm:overflow-hidden max-sm:p-[0.2rem]"
      >
        <div className="max-sm:w-screen flex flex-row md:flex-nowrap max-sm:flex-wrap max-sm:overflow-y-auto gap-[2.5rem] max-sm:gap-[1.25rem]">
          <Box.Step step={1} {...realEstateProduct} />
          <Box.Step step={4} {...realEstateProduct} />
        </div>
        <div className="max-sm:w-screen flex flex-row md:flex-nowrap max-sm:flex-wrap max-sm:overflow-y-auto gap-[2.5rem] max-sm:gap-[1.25rem] ">
          <Box.Step step={2} {...realEstateProduct} />
          <Box.Step step={3} {...realEstateProduct} />
        </div>
      </div>
      <div
        className="md:w-full max-sm:!w-screen md:h-[35.312rem] max-sm:h-auto flex flex-col items-start
            justify-start gap-[0.937rem] p-[1.25rem] pt-[2.3rem] shadow-sm rounded-[0.937rem]
            max-sm:border-[0.1rem] max-sm:border-[#e0e0e0] max-sm:p-[1rem]"
      >
        <input 
          type="checkbox" 
          name="select-houses-to-delete" 
          id="select-houses-to-delete" 
          className='peer/HousesToDelete hidden'
        />

        <div className="w-full h-[3.3125rem] flex flex-row items-center justify-start gap-[0.937rem]">
          <img src={addHomeWorkIcon} />
          <button
            onClick={handleToAddHouseStep}
            type="button"
            title="Editar Casas"
            className="outline-none border-none flex flex-row items-center justify-center"
          >
            <img src={editIcon} />
          </button>
        </div>

        <span
          data-hasselection={!!housesSelecteds.length} 
          data-hashouses={!!houses.length} 
          className="
            w-full
            font-semibold 
            text-[1.125rem] text-#414142
            flex flex-row items-center justify-between
            data-[hashouses=true]:data-[hasselection=false]:peer-checked/HousesToDelete:[&_.to-unselect-house]:flex
            data-[hashouses=true]:data-[hasselection=false]:peer-checked/HousesToDelete:[&_.to-select-house]:hidden
            data-[hashouses=true]:data-[hasselection=true]:[&_.to-select-house]:hidden
            data-[hashouses=true]:data-[hasselection=true]:peer-checked/HousesToDelete:[&_.to-delete-selected-houses]:flex            
          "
        >
          Casas adicionadas
          <label
            data-hashouses={!!houses.length} 
            data-hasselection={!!housesSelecteds.length} 
            className='to-select-house text-emerald-700 cursor-pointer select-none data-[hashouses=false]:hidden' 
            htmlFor='select-houses-to-delete'>Selecionar</label>
          <label
            data-hashouses={!!houses.length} 
            data-hasselection={!!housesSelecteds.length} 
            className='to-unselect-house hidden text-emerald-700 cursor-pointer select-none' 
            htmlFor='select-houses-to-delete'>Voltar</label>
          <label
            data-indelete={houseInDelete}
            data-hashouses={!!houses.length} 
            data-hasselection={!!housesSelecteds.length} 
            role='button'            
            onClick={() => handleDeleteHouses(0)}
            className='to-delete-selected-houses 
              hidden 
              text-emerald-700 
              cursor-pointer select-none
              data-[indelete=true]:text-emerald-300
              data-[indelete=true]:cursor-not-allowed
            '
          >
            <IoTrash />
          </label>
        </span>

        <div
          className="flex flex-row flex-wrap items-start justify-start
              max-sm:overflow-x-auto md:overflow-y-auto gap-[1rem] gap-x-[2.3rem] min-h-auto
              max-h-[39.312rem] w-full pt-2 pb-1 
              peer-checked/HousesToDelete:[&_.container-house-box_.box-to-select-house]:flex"
        >          
          {houses.map((house, index) => (
            <span 
              key={index} 
              className='
                container-house-box 
                relative
              '
            >
              <span                
                className='
                  box-to-select-house                  
                  absolute
                  top-1
                  left-1 
                  w-8 h-8
                  transition-all
                  duration-[0.3s]
                  border-gray-300 border-[1px] border-solid
                  bg-gray-100/50
                  rounded-sm
                  cursor-pointer
                  scale-1
                  z-50                  
                  
                  hidden

                  active:scale-105
                  md:hover:scale-105
                  md:hover:active:scale-110
                '
              >
                <input type="checkbox" 
                  name={`house-check-${house.id}`} 
                  id={`house-check-${house.id}`} 
                  className="peer/CheckHouse hidden check-house-input" 
                />
                <label
                  onClick={() => setSelectedHouses(house.real_estate_product_id, house)}
                  role='button'
                  htmlFor={`house-check-${house.id}`} 
                  className="w-8 h-8
                    flex flex-row 
                    flex-nowrap
                    items-center justify-center
                    peer-checked/CheckHouse:[&_.house-check-icon]:flex
                    peer-checked/CheckHouse:bg-gray-200
                    cursor-pointer
                  "
                >
                  <MdCheck size={16} className='hidden house-check-icon text-emerald-500' />
                </label>
              </span>
              <Box.House
                {...house}
                projectID={projectID || '-1'}
                housePosition={index}
              />
            </span>
          ))}

          {housesOnLoading && houses.length <= 0 && (
            <>
              <Box.Mock />
              <Box.Mock />
              <Box.Mock />
              <Box.Mock />
            </>
          )}

          {!housesOnLoading && houses.length <= 0 && (
            <div className="flex-1 h-full flex flex-col items-center justify-center gap-[2.5rem]">
              <div className="flex flex-col items-center justify-center gap-[0.5rem]">
                <span>
                  <img src={addHomeWork} />
                </span>
                <span className="text-[1.125rem] font-semibold text-[#414142]">
                  Casas adicionadas
                </span>
                <span className="font-[400] text-[#B8B9BA]">
                  Complete esta seção para finalizar seu estudo
                </span>
              </div>
              <button
                type="button"
                onClick={handleToAddHouseStep}
                className="rounded-[0.937rem] w-[9.375rem] h-[3.375rem] bg-[#39A887]
                        flex flex-row items-center justify-center font-bold text-white hover:bg-[#338f73] duration-[0.3s] transition-colors"
              >
                Completar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
