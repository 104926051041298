import { AIGPT } from "@kemis/harvey-ai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { BiExpandAlt } from "react-icons/bi";
import { BsSoundwave, BsSpeedometer2 } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineVolumeUp } from "react-icons/hi";
import { IoAdd, IoChevronUp, IoCloseOutline, IoReloadOutline } from "react-icons/io5";
import { MdContentCopy, MdKeyboardDoubleArrowUp } from "react-icons/md";
import { RiMic2Line } from "react-icons/ri";
import mcfAISoloIcon from "~/assets/icons/mcf-ai-solo.svg";
import mcfAIIcon from "~/assets/icons/mcf-ai.svg";

// Types
import { IAssistant, IAssistantThreadMessage, TThreadRunStatus } from "@kemis/harvey-ai/@types";
import { IChatProps } from "./types";

// Styles
import "./styles.css";

// Partials
import { Button } from "./Partials/Button";
import { Label } from "./Partials/Label";

// Utils
import { debounce } from "~/utils/debounce";

// Hooks
import { useAuthContext } from "~/hooks/contexts/Auth";
import { useSpeech } from "~/hooks/Speech";

export function Chat(_props: IChatProps) {
    const { user } = useAuthContext()
    const [prompt, setPrompt] = useState<string | null>(null)
    const [showHomePage, setHomePage] = useState<boolean>(false)
    const [assistant, setAssistant] = useState<IAssistant | undefined>(undefined)
    const [assistants, setAssistants] = useState<IAssistant[]>([])
    const [messages, setMessage] = useState<IAssistantThreadMessage[]>([])
    const [threadStatus, setThreadStatus] = useState<TThreadRunStatus>('completed')
    const [onThread, setOnThread] = useState<boolean>(false)

    // AUX Variables
    const DEBOUNCE_TIME_ALREADY_PROMPT = 225
    const HARVEY_AI = useMemo(() => new AIGPT(user, null,
        [], 'rep-chat-assitant',
        'gpt-4o',
        process.env?.REACT_APP_OPENAI_CHAT_GPT_TEST as string || undefined
    ),
        []);
    const DISPLAY_CONTROLLER_ELEMENT = useRef<any>()
    const INPUT_PROPMT_ELEMENT = useRef<any>()
    const DISPLAY_ASSISTANT_CONTROLLER_ELEMENT = useRef<any>()
    const SHOW_HOMEPAGE_AI = useMemo(() => {
        if (!showHomePage && !prompt && messages.length <= 0) {
            return true;
        } else if (!showHomePage && messages.length <= 0) {
            return true;
        } else if (!showHomePage && !prompt && messages.length > 0) {
            return false;
        } else if (!showHomePage && (prompt || messages.length > 0)) {
            return false;
        } else if (showHomePage && prompt) {
            return false;
        } else {
            return true;
        }
    }, [prompt, showHomePage, messages])
    const SHOW_THREAD_AI = useMemo(() => {
        if ((prompt && !SHOW_HOMEPAGE_AI) || (!SHOW_HOMEPAGE_AI && messages.length > 0)) return true;

        return false;
    }, [prompt, SHOW_HOMEPAGE_AI, messages])
    const CHANGE_PROMPT_BOX_UI = useMemo(() => prompt && true, [prompt])
    const HOMEPAGAI_ACTIONS = useMemo(() => ({
        "action_one": ' Criar um resumo sobre uma casa ideal no centro de São Paulo/SP',
        "action_two": 'Me passe os dados de um projeto para calcular a viabilidade.',
        "action_three": 'Calcule o lucro líquido de um imóvel com as seguintes condições...',
        "action_four": 'Quais os cenários de lucro para a venda deste imóvel em 12, 15 e 20 meses?',
        "action_five": 'Qual seria a renda necessária para um investidor financiar este projeto?',
    }), [])
    const {
        onSpeaking,
        onAudioStart,
        onSpeechError,
        onSpeechErrorMessage,
        onSpeechTranscriptionData,
        handlerOnSetVoiceToPrompt,
        handlerOnSetSpeechToSpeak,
        handlerOnSetStopSpeechToSpeak,
        handlerOnSetStopVoiceToPrompt,
        handlerOnSetSpeechToSpeakRate,
        handlerOnSetSpeechToSpeakVoice,
        handlerOnSetSpeechToSpeakPitch,
        handlerOnSetSpeechToSpeakVolume,
        handlerOnGetSpeechToSpeakVoices,
        handlerOnSetClearSpeechTranscriptionData,
    } = useSpeech({
        onSpeechRecognitionCallBackFn: () => {
            if (INPUT_PROPMT_ELEMENT) INPUT_PROPMT_ELEMENT.current?.focus() || INPUT_PROPMT_ELEMENT.current?.click();
        }
    })

    /**
     * Close the chat with base on side-effect (User Click)
     * And clear all remain data
     */
    const handlerOnSetOffDisplayController = useCallback(() => {

        if (onAudioStart) {
            Swal.fire({
                icon: 'warning',
                text: 'Você está transcrevendo sua voz'
            })
            return;
        }

        if (INPUT_PROPMT_ELEMENT && (INPUT_PROPMT_ELEMENT.current as HTMLDivElement)?.childNodes[0]) {
            // @ts-ignore
            (INPUT_PROPMT_ELEMENT.current as HTMLDivElement).childNodes[0].innerText = ' '
        }

        setPrompt(null)
        setMessage([])
        handlerOnSetClearSpeechTranscriptionData()
        handlerOnSetStopSpeechToSpeak()

        DISPLAY_CONTROLLER_ELEMENT.current?.click()
    }, [DISPLAY_CONTROLLER_ELEMENT, onAudioStart, INPUT_PROPMT_ELEMENT, setPrompt, handlerOnSetClearSpeechTranscriptionData])

    /**
     * Show the homepage with base on side-effect (User click)
     */
    const handlerOnSetToHomePageAI = useCallback(() => {

        if (prompt) {
            // TODO - THE "HOMEPAGE AI" WILL BE SHOWED ONLY IF DON'T HAVE ANY PROMPT
            setPrompt(null)
        }

        // TODO - THE LOGIC TO SHOW IS: SHOW ONLY BE DON'T HAVE PROMPT AND THE "HOMEPAGE AI" ALREADY CLOSED
        setHomePage(!showHomePage)
    }, [setHomePage, setPrompt, showHomePage, showHomePage, prompt])

    /**
     * Set a prompt with base on already prompt from homepage
     */
    const handlerOnSetAlreadyPrompt = useCallback((prompAlready: string | undefined = undefined) => {
        if (!prompAlready || !INPUT_PROPMT_ELEMENT || !assistant) return;

        const PROMPT_PARSED = prompAlready.trim();

        if ((INPUT_PROPMT_ELEMENT.current as HTMLDivElement)?.childNodes[0])
            // @ts-ignore
            (INPUT_PROPMT_ELEMENT.current as HTMLDivElement).childNodes[0].innerText = PROMPT_PARSED


        setPrompt(PROMPT_PARSED);

        handleOnSetNewThreadPrompts(PROMPT_PARSED);
    }, [setPrompt, assistant])

    /**
     * Set a assistant and close the UI List
     */
    const handlerOnSetAssistant = useCallback((assistantSelected: IAssistant | null = null) => {
        if (!assistantSelected) return;

        // TODO - AUTO CLOSE THE LIST OS ASSISTANT
        DISPLAY_ASSISTANT_CONTROLLER_ELEMENT?.current?.click()

        setAssistant(assistantSelected);
    }, [setAssistant])

    /**
     * Create a new thread of conversation
     */
    const handleOnSetNewThread = useCallback(async () => {
        try {
            if (!HARVEY_AI || !assistant) return;

            if (HARVEY_AI.threadID && !(await HARVEY_AI.setDeleteThread()).deleted)
                throw new Error('The last thread cannot be deleted');

            HARVEY_AI.setCreateThread();
        } catch (error) {
            HARVEY_AI?.setCreateThread();
        }
    }, [HARVEY_AI, assistant])

    /**
     * Delete the actual thread of conversation
     */
    const handleOnSetDeleteThread = useCallback(async () => {
        try {
            if (!HARVEY_AI) return;

            if (HARVEY_AI.threadID && !(await HARVEY_AI.setDeleteThread()).deleted)
                throw new Error('The last thread cannot be deleted');

        } catch (error) {
            // do anything
        }
    }, [HARVEY_AI])

    /**
     * Set to stop or start the recognition
     */
    const handlerOnVoiceToPrompt = useCallback(() => {
        try {
            if (!onAudioStart) {
                handlerOnSetVoiceToPrompt()
            } else {
                handlerOnSetStopVoiceToPrompt()
            }
        } catch (error) {
            // do anything;
        }
    }, [handlerOnSetStopVoiceToPrompt, handlerOnSetVoiceToPrompt, onAudioStart])

    /**
     * Set the prompt with base on input event on area text
     * @param promptRaw The input from user on area text
     * @returns 
     */
    function handlerOnInputPrompt(promptRaw: string | undefined = undefined) {
        try {
            if (!promptRaw || typeof promptRaw !== 'string' || promptRaw.trim() === '') {
                setPrompt(null);
                return;
            }

            setPrompt(promptRaw.trim());
        } catch (error) {
            // do anything
        }
    }

    /**
     * Get all assistants from API KEY
     */
    async function getAssistants() {
        try {
            const chat_assistants = await HARVEY_AI.getChatAssitants()

            if (!chat_assistants) throw new Error('');

            setAssistants(chat_assistants.data as unknown as IAssistant[])
        } catch (error) {
            // do anything
        }
    }

    function handleCopyToClipboard(text: string | null = null) {
        try {
            if (!text) throw new Error('');

            if (!window.navigator?.clipboard) throw new Error('');

            window.navigator?.clipboard.writeText(text).then(_r => Swal.fire({
                icon: 'info',
                text: 'Copiado para área de tranferência'
            }))
        } catch (error) {
            // do anything
        }
    }

    /**
     * Send a mensage to actual thread and set the message state with all text's (I/O) from thread 
     */
    const handleOnSetNewThreadPrompts = useCallback(async (promptData) => {
        try {
            setOnThread(true);

            if (!HARVEY_AI) throw new Error('The HARVEY_AI statement is missing');

            if (!promptData) throw new Error('The prompt is missing');


            const PROMPT_VALUE = promptData

            if (!PROMPT_VALUE) throw new Error('The prompt value is not valid');

            const THREAD_PROMPTS = ((
                await (
                    await HARVEY_AI.setCreateMessage(PROMPT_VALUE, 'user', []))
                    .onThreadStream((args: TThreadRunStatus) => {
                        console.warn('CALLBACK WAS CALLED ON EVENT STREAM', args);

                        setThreadStatus(args);
                    })
                    .setRunThread())?.data || []) as unknown as IAssistantThreadMessage[];

            console.table(THREAD_PROMPTS)

            setOnThread(false);
            setMessage(THREAD_PROMPTS);
        } catch (error) {
            setOnThread(false);
            console.table(error)
        }
    },
        [HARVEY_AI])

    useEffect(() => {
        if (assistants.length >= 1 || assistant) return;

        getAssistants()
        handlerOnSetStopSpeechToSpeak()
    }, [])

    useEffect(() => {
        if (!assistant) return;

        // TODO - EVERYTIME THAT "ASSISTANT STATE" CHANGE, WILL BE DELETED THE ACTUAL/PREV THREAD
        // AND CREATED A NEW THREAD BASED ON NEW ASSISTANT
        handleOnSetDeleteThread().then(_r => {
            HARVEY_AI?.set('assistantID', assistant.id)
            handleOnSetNewThread()
        })
    }, [assistant])

    useEffect(() => {
        if (!onSpeechTranscriptionData || typeof onSpeechTranscriptionData !== 'string' || onSpeechTranscriptionData.trim() === '') return;

        if (!INPUT_PROPMT_ELEMENT) return;

        if ((INPUT_PROPMT_ELEMENT.current as HTMLDivElement)?.childNodes[0])
            // @ts-ignore
            (INPUT_PROPMT_ELEMENT.current as HTMLDivElement).childNodes[0].innerText = onSpeechTranscriptionData

    }, [onSpeechTranscriptionData])

    useEffect(() => {
        if (!onSpeechError) return;

        Swal.fire({
            icon: 'warning',
            text: onSpeechErrorMessage || ''
        })
    }, [onSpeechError, onSpeechErrorMessage])

    return <>
        <input
            type="checkbox"
            name="mcf-ai-display-controller"
            id="mcf-ai-display-controller"
            className="peer/MCFDisplayController hidden"
            ref={DISPLAY_CONTROLLER_ELEMENT}
        />

        <input
            type="checkbox"
            name="mcf-ai-display-expand-controller"
            id="mcf-ai-display-expand-controller"
            className="peer/MCFDisplayExpandController hidden"
        />

        <label
            title="MCF AI - Assitentes"
            htmlFor="mcf-ai-display-controller"
            className="w-12 h-12 
                p-2 rounded-lg
                cursor-pointer
                hover:bg-slate-300
                flex flex-row items-center justify-center
            "
        >
            <img src={mcfAISoloIcon} alt="Duas mãos se cruzando" />
        </label>

        <div id="mcf-ai-aside-container"
            className="
                hidden
                fixed
                rounded-xl
                border-[1px] border-slate-200
                shadow-xl

                peer-checked/MCFDisplayController:flex

                flex-col gap-0
                
                max-sm:!w-screen
                max-sm:!mt-[70px]

                overflow-x-hidden
            "
        >
            <span className="w-full h-16 border-b-[1px] border-slate-300
                    bg-white
                    flex flex-row 
                    flex-nowrap items-center justify-between
                    gap-4
                    pl-4
                    pr-4 pt-2
                "
            >
                <span className="flex flex-1 flex-row flex-nowrap items-center gap-4 text-sm font-semibold text-slate-800">
                    {/* <span className="select-none cursor-default">
                        <img src={mcfAISoloIcon} alt="MCF AI" className="max-w-[30px] h-auto" />
                    </span> */}

                    <span className="flex-1 md:max-w-[230px] h-auto
                            flex flex-col
                            relative
                        "
                    >
                        <input
                            type="checkbox"
                            name="mcf-ai-assistant-display-controller"
                            id="mcf-ai-assistant-display-controller"
                            className="peer/MCFDisplayAssistantController hidden"
                            ref={DISPLAY_ASSISTANT_CONTROLLER_ELEMENT}
                        />

                        <label
                            data-hasassistant={assistant && true}
                            htmlFor="mcf-ai-assistant-display-controller"
                            className="w-auto px-4 py-2 h-auto cursor-pointer 
                                bg-gray-50
                                hover:bg-gray-100
                                hover:active:bg-gray-200
                                active:bg-gray-100
                                rounded-xl
                                peer-checked/MCFDisplayAssistantController:bg-transparent
                                peer-checked/MCFDisplayAssistantController:border-t-[1px]
                                peer-checked/MCFDisplayAssistantController:border-l-[1px]
                                peer-checked/MCFDisplayAssistantController:border-r-[1px]
                                peer-checked/MCFDisplayAssistantController:border-gray-300
                                peer-checked/MCFDisplayAssistantController:rounded-none
                                peer-checked/MCFDisplayAssistantController:rounded-tl-xl
                                peer-checked/MCFDisplayAssistantController:rounded-tr-xl
                                
                                text-ellipsis
                                overflow-hidden
                                whitespace-nowrap

                                flex
                                flex-row flex-nowrap items-center
                                select-none
                                transition-all
                                duration-[0.3s]
                                z-[50]
                            "
                        >
                            <span data-hasassistant={assistant && true} className="hidden data-[hasassistant=true]:flex ">
                                {assistant?.name || 'N/A'}
                            </span>
                            <span data-noassistant={!assistant && true} className="hidden data-[noassistant=true]:flex">
                                Selecione um assitante
                            </span>
                        </label>

                        <ul className="w-full
                                h-auto
                                px-2 pt-4 pb-2
                                absolute
                                flex flex-col gap-1
                                m-0
                                top-4
                                rounded-bl-xl
                                rounded-br-xl
                                z-[-1]
                                pointer-events-none
                                opacity-100
                                bg-white
                                border-l-[1px]
                                border-r-[1px]
                                border-b-[1px]
                                border-gray-300
                                peer-checked/MCFDisplayAssistantController:opacity-0
                                peer-checked/MCFDisplayAssistantController:pointer-events-auto
                                peer-checked/MCFDisplayAssistantController:z-40
                                peer-checked/MCFDisplayAssistantController:top-6
                                transition-all
                                duration-[0.15s]
                            "
                        >
                            <span data-noassistant={assistants.length <= 0} className="hidden data-[noassistant=true]:flex">
                                Nenhum assistente disponivel!
                            </span>

                            {
                                assistants.map(ass => (
                                    <li
                                        key={ass.id}
                                        title={ass.name}
                                        data-selected={ass.id === assistant?.id}
                                        onClick={() => handlerOnSetAssistant(ass)}
                                        className="w-full px-4 py-2 h-10 cursor-pointer 
                                            bg-slate-50
                                            data-[selected=true]:bg-slate-50/50
                                            data-[selected=false]:hover:bg-slate-100
                                            data-[selected=false]:hover:active:bg-slate-200
                                            data-[selected=false]:active:bg-slate-100
                                            border-[1px] 
                                            border-slate-300 rounded-xl
                                            flex flex-row flex-nowrap items-center
                                            select-none
                                            transition-all
                                            duration-[0.3s]
                                            z-[50]
                                            list-style-none
                                            text-xs
                                        "
                                    >
                                        <span>{ass.name}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </span>
                </span>

                <span className="w-auto flex flex-row flex-nowrap items-center justify-start gap-2">
                    <Button
                        title="Inicar uma nova conversa"
                        data-hasassistent={assistant && true}
                        onClick={() => debounce(150, handleOnSetNewThread)}
                        className="bg-black/0 data-[hasassistent=false]:opacity-50 data-[hasassistent=false]:cursor-not-allowed"
                    >
                        <IoReloadOutline size={16} className="text-slate-800" />
                    </Button>
                    <span className="relative">
                        <input
                            type="checkbox"
                            name="mcf-ai-display-config-controller"
                            id="mcf-ai-display-config-controller"
                            className="peer/MCFDisplayConfigController hidden"
                        />

                        <Label
                            htmlFor="mcf-ai-display-config-controller"
                            className="bg-black/0 cursor-pointer" title="Configurações">
                            <FiSettings size={16} className="text-slate-800" />
                        </Label>

                        <ul className="w-56
                                h-auto
                                px-2 pt-2 pb-2
                                absolute
                                flex flex-col gap-3 items-center
                                m-0
                                top-10
                                -left-32
                                rounded-bl-xl
                                rounded-br-xl
                                z-[-1]
                                pointer-events-none
                                opacity-100
                                bg-white
                                border-l-[1px]
                                border-r-[1px]
                                border-b-[1px]
                                border-gray-300
                                peer-checked/MCFDisplayConfigController:opacity-0
                                peer-checked/MCFDisplayConfigController:pointer-events-auto
                                peer-checked/MCFDisplayConfigController:z-40
                                peer-checked/MCFDisplayConfigController:top-12
                                transition-all
                                duration-[0.15s]
                            "
                        >
                            <label
                                htmlFor="mcf-ai-display-expand-controller"
                                className="w-full px-2 py-2 h-10 cursor-pointer
                                    hover:bg-slate-100
                                    hover:active:bg-slate-200
                                    active:bg-slate-100
                                    border-slate-300 rounded-xl
                                    hidden
                                    md:flex
                                    flex-row flex-nowrap items-center
                                    gap-4
                                    select-none
                                    transition-all
                                    duration-[0.3s]
                                    z-[50]
                                    list-style-none
                                    text-xs
                                    font-medium
                                    text-black
                                "
                            >
                                <BiExpandAlt size={17} />
                                Expandir
                            </label>

                            <span className="hidden md:flex w-2/3 h-0.5 bg-gray-100/60 rounded-full"></span>

                            <li
                                title="Alterar entonação da voz"
                                className="w-full px-2 py-2 h-10 cursor-pointer
                                    hover:bg-slate-100
                                    hover:active:bg-slate-200
                                    active:bg-slate-100
                                    border-slate-300 rounded-xl
                                    flex flex-row flex-nowrap items-center
                                    gap-4
                                    select-none
                                    transition-all
                                    duration-[0.3s]
                                    z-[50]
                                    list-style-none
                                    text-xs
                                    font-medium
                                    text-black
                                "
                            >
                                <MdKeyboardDoubleArrowUp size={17} />

                                <input type="range" disabled={(!assistants && true) || onSpeaking} min={0} max={100} defaultValue={50} onChange={(e) => debounce(150, handlerOnSetSpeechToSpeakPitch, ((e.target.value || 100) as unknown as number / 100))} />
                            </li>
                            <li
                                title="Alterar volume da voz"
                                className="w-full px-2 py-2 h-10 cursor-pointer
                                    hover:bg-slate-100
                                    hover:active:bg-slate-200
                                    active:bg-slate-100
                                    border-slate-300 rounded-xl
                                    flex flex-row flex-nowrap items-center
                                    gap-4
                                    select-none
                                    transition-all
                                    duration-[0.3s]
                                    z-[50]
                                    list-style-none
                                    text-xs
                                    font-medium
                                    text-black
                                "
                            >
                                <HiOutlineVolumeUp size={17} />

                                <input type="range" disabled={(!assistants && true) || onSpeaking} min={0} max={100} defaultValue={50} onChange={(e) => debounce(150, handlerOnSetSpeechToSpeakVolume, ((e.target.value || 100) as unknown as number / 100))} />
                            </li>
                            <li
                                title="Alterar velocidade da voz"
                                className="w-full px-2 py-2 h-10 cursor-pointer
                                    hover:bg-slate-100
                                    hover:active:bg-slate-200
                                    active:bg-slate-100
                                    border-slate-300 rounded-xl
                                    flex flex-row flex-nowrap items-center
                                    gap-4
                                    select-none
                                    transition-all
                                    duration-[0.3s]
                                    z-[50]
                                    list-style-none
                                    text-xs
                                    font-medium
                                    text-black
                                "
                            >
                                <BsSpeedometer2 size={17} />

                                <input type="range" disabled={(!assistants && true) || onSpeaking} min={0} max={100} defaultValue={50} onChange={(e) => debounce(150, handlerOnSetSpeechToSpeakRate, ((e.target.value || 100) as unknown as number / 100))} />
                            </li>

                            <li
                                title="Alterar voz"
                                className="w-full px-2 h-auto                                   
                                    flex flex-row flex-nowrap items-center
                                    gap-4
                                    select-none
                                    transition-all
                                    duration-[0.3s]
                                    z-[50]
                                    list-style-none
                                    text-xs
                                    font-medium
                                    text-black
                                    py-2

                                "
                            >
                                <BsSoundwave size={17} />

                                <span className="flex-1 h-full flex flex-row flex-nowrap items-center gap-2 overflow-y-hidden overflow-x-auto">
                                    {
                                        handlerOnGetSpeechToSpeakVoices()?.map(voice => (
                                            <Button
                                                onClick={() => debounce(100, handlerOnSetSpeechToSpeakVoice, voice)}
                                                role="button"
                                                data-disabled={onSpeaking}
                                                disabled={onSpeaking}
                                                title={voice.name}
                                                key={voice.name}
                                                className="min-w-[112px] w-28 h-10 
                                                    hover:bg-slate-100
                                                    hover:active:bg-slate-200
                                                    active:bg-slate-100
                                                    border-slate-300 rounded-md
                                                    flex flex-row flex-nowrap items-center justify-center
                                                    cursor-pointer
                                                    px-2

                                                    data-[disabled=true]:cursor-not-allowed
                                                    data-[disabled=true]:pointer-events-none
                                                "
                                            >
                                                <p className="overflow-hidden
                                                    text-ellipsis
                                                    whitespace-nowrap text-sm">
                                                    {voice.name}
                                                </p>
                                            </Button>
                                        ))
                                    }
                                </span>
                            </li>
                        </ul>
                    </span>

                    <Button className="bg-black/0" title="Fechar Assistente" onClick={handlerOnSetOffDisplayController}>
                        <IoCloseOutline size={24} className="text-red-500" />
                    </Button>
                </span>
            </span>

            <span
                data-showhome={SHOW_HOMEPAGE_AI}
                className="
                    w-full flex-1
                    overflow-y-auto
                    px-8 pt-4 pb-24 
                    hidden
                    data-[showhome=true]:flex
                    flex-col 
                    items-center
                    gap-4
                    bg-slate-50
                "
            >

                <span className="w-full flex-1 min-h-[30%] flex flex-col gap-2 items-center justify-center select-none cursor-default">
                    <span>
                        <img src={mcfAIIcon} alt="MCF AI" className="max-sm:max-w-[90px] md:max-w-[120px] h-auto pointer-events-none" />
                    </span>

                    <span className="font-semibold text-gray-500 text-2xl text-center">Olá, {user?.name || 'N/A'}</span>
                    <span className="font-semibold text-md text-gray-900">Como o MCF AI pode te ajudar?</span>
                    <span className="font-light text-sm text-gray-400">Não esqueça de selecionar seu assistente.</span>
                </span>

                <span className="block w-full font-bold text-md text-[#212529] text-center select-none cursor-default">
                    Explore o poder do assitante
                </span>

                <span className="w-full h-auto pb-4 flex flex-row flex-nowrap items-center justify-between gap-4 overflow-x-auto">
                    <Button
                        title={HOMEPAGAI_ACTIONS.action_two}
                        data-allowed={!(!assistant && true)}
                        disabled={!assistant && true}
                        onClick={() => debounce(DEBOUNCE_TIME_ALREADY_PROMPT, handlerOnSetAlreadyPrompt, HOMEPAGAI_ACTIONS.action_two)}
                        className="button-gradient-type-one flex-col gap-2 p-5 w-full h-auto rounded-3xl data-[allowed=false]:opacity-50 data-[allowed=false]:cursor-not-allowed"
                    >
                        <p className="w-full text-md font-medium text-[#212529] overflow-hidden text-ellipsis whitespace-nowrap">
                            {HOMEPAGAI_ACTIONS.action_two}
                        </p>
                    </Button>

                    <Button
                        title={HOMEPAGAI_ACTIONS.action_three}
                        data-allowed={!(!assistant && true)}
                        disabled={!assistant && true}
                        onClick={() => debounce(DEBOUNCE_TIME_ALREADY_PROMPT, handlerOnSetAlreadyPrompt, HOMEPAGAI_ACTIONS.action_three)}
                        className="button-gradient-type-two flex-col gap-2 p-5 w-full h-auto rounded-3xl data-[allowed=false]:opacity-50 data-[allowed=false]:cursor-not-allowed"
                    >
                        <p className="w-full text-md font-medium text-[#212529] overflow-hidden text-ellipsis whitespace-nowrap">
                            {HOMEPAGAI_ACTIONS.action_three}
                        </p>
                    </Button>

                    <Button
                        title={HOMEPAGAI_ACTIONS.action_four}
                        data-allowed={!(!assistant && true)}
                        disabled={!assistant && true}
                        onClick={() => debounce(DEBOUNCE_TIME_ALREADY_PROMPT, handlerOnSetAlreadyPrompt, HOMEPAGAI_ACTIONS.action_four)}
                        className="button-gradient-type-three flex-col gap-2 p-5 w-full h-auto rounded-3xl data-[allowed=false]:opacity-50 data-[allowed=false]:cursor-not-allowed"
                    >
                        <p className="w-full text-md font-medium text-[#212529] overflow-hidden text-ellipsis whitespace-nowrap">
                            {HOMEPAGAI_ACTIONS.action_four}
                        </p>
                    </Button>

                    <Button
                        title={HOMEPAGAI_ACTIONS.action_five}
                        data-allowed={!(!assistant && true)}
                        disabled={!assistant && true}
                        onClick={() => debounce(DEBOUNCE_TIME_ALREADY_PROMPT, handlerOnSetAlreadyPrompt, HOMEPAGAI_ACTIONS.action_five)}
                        className="button-gradient-type-four flex-col gap-2 p-5 w-full h-auto rounded-3xl data-[allowed=false]:opacity-50 data-[allowed=false]:cursor-not-allowed"
                    >
                        <p className="w-full text-md font-medium text-[#212529] overflow-hidden text-ellipsis whitespace-nowrap">
                            {HOMEPAGAI_ACTIONS.action_five}
                        </p>
                    </Button>
                </span>
            </span>

            <ul
                id="mcf-ai-display-list"
                data-showlist={SHOW_THREAD_AI}
                className="w-full 
                    flex-1 overflow-y-auto 
                    px-8 pt-4 pb-36
                    
                    hidden

                    data-[showlist=true]:flex 

                    flex-col gap-5
                    overflow-x-hidden
                "
            >

                {
                    messages.map(message => {
                        return (
                            <li
                                id={message.id}
                                data-ismy={!message.assistant_id && true}
                                data-fromassistant={!(!message.assistant_id && true)}
                                className="
                                    w-full
                                    h-auto
                                    data-[fromassistant=true]:w-full
                                    data-[fromassistant=false]:break-words
                                    data-[fromassistant=false]:whitespace-pre-wrap
                                    data-[fromassistant=false]:bg-[#fce3cd]
                                    data-[fromassistant=false]:max-w-[70%]
                                    data-[fromassistant=false]:self-end
                                    data-[fromassistant=false]:p-4
                                    data-[fromassistant=false]:rounded-xl
                                    text-md text-gray-900 font-normal
                                    transition-all
                                    duration-[0.15s]
                                    will-change-auto
                                    relative
                                "
                            >
                                <span dangerouslySetInnerHTML={{
                                    __html: message.content[0].text.value
                                }} />
                                <span
                                    data-showme={onSpeaking}
                                    data-fromassistant={message.assistant_id && true}
                                    className="option-action-bar
                                        w-full 
                                        h-5 
                                        absolute 
                                        left-0
                                        -bottom-7
                                        px-2
                                        hidden
                                        data-[fromassistant=true]:flex 
                                        flex-row 
                                        flex-nowrap items-center 
                                        justify-start gap-3 
                                        hover:[&._options-action]:flex
                                    "
                                >
                                    <Button
                                        title={!onSpeaking ? "Ouvir resposta" : 'Parar de ouvir'}
                                        onClick={() => {
                                            if (!onSpeaking) {
                                                debounce(225, handlerOnSetSpeechToSpeak, message.content[0].text.value)
                                            } else {
                                                debounce(125, handlerOnSetStopSpeechToSpeak)
                                            }
                                        }}
                                        className="w-6 h-6 bg-black/0 p-[3px] rounded-md options-action hidden data-[showme=true]:!flex flex-row items-center gap-2 text-gray-500"
                                    >
                                        <BsSoundwave
                                            size={36}
                                            data-speaking={onSpeaking}
                                            className="data-[speaking=true]:text-red-500 data-[speaking=true]:animate-pulse"
                                        />
                                    </Button>

                                    <Button
                                        title="Copiar Resposta"
                                        className="w-6 h-6 bg-black/0 p-[3px] rounded-md options-action hidden flex-row items-center gap-2 text-gray-500"
                                        onClick={() => handleCopyToClipboard(message.content[0].text.value)}
                                    >
                                        <MdContentCopy size={36} />
                                    </Button>

                                    {/* <Button title="Resposta Insatisfatória" className="w-6 h-6 bg-black/0 p-[3px] rounded-md options-action hidden flex-row items-center gap-2 text-gray-500">
                                        <AiOutlineDislike size={36} />
                                    </Button>
                                    <Button title="Resposta Satisfatória" className="w-6 h-6 bg-black/0 p-[3px] rounded-md options-action hidden flex-row items-center gap-2 text-gray-500">
                                        <AiOutlineLike size={36} />
                                    </Button> */}
                                </span>
                            </li>
                        )
                    })
                }

                <li
                    data-ismy={prompt && true}
                    data-fromassistant={false}
                    data-show={onThread}
                    className="
                        w-full
                        h-auto
                        hidden
                        data-[show=true]:block
                        data-[fromassistant=true]:w-full
                        data-[fromassistant=false]:break-words
                        data-[fromassistant=false]:whitespace-pre-wrap
                        data-[fromassistant=false]:bg-[#fce3cd]
                        data-[fromassistant=false]:max-w-[70%]
                        data-[fromassistant=false]:self-end
                        data-[fromassistant=false]:p-4
                        data-[fromassistant=false]:rounded-xl
                        text-md text-gray-900 font-normal
                        transition-all
                        duration-[0.15s]
                        will-change-auto
                        relative
                        animate-pulse
                    "
                >
                    <span>{prompt}</span>
                </li>

                <span
                    data-show={onThread}
                    className="w-2 h-2 bg-black animate-ping rounded-full hidden data-[show=true]:flex"
                />

                <span data-allowed={!(!assistant && true)}
                    className="hidden 
                        data-[allowed=false]:flex tex-sm 
                        font-semibold text-gray-600
                    "
                >
                    Selecione um assistente
                </span>
            </ul>

            <span
                data-hasprompt={CHANGE_PROMPT_BOX_UI}
                className="
                    w-[90%] 
                    min-h-[64px]
                    rounded-[30px]
                    px-4 py-2
                    self-end
                    absolute
                    bottom-[2vh]
                    left-1/2
                    -translate-x-1/2
                    gap-2
                    border-[1px] border-slate-200 shadow-xl
                    bg-white/30
                    backdrop-blur-md
                    flex flex-row flex-nowrap 
                    items-center justify-start
                    data-[hasprompt=true]:h-auto
                    data-[hasprompt=true]:items-end
                    data-[hasprompt=true]:overflow-x-hidden
                    transition-all
                    duration-[0.15s]
                "
            >
                <Button
                    title="Ir a Página Inicial"
                    onClick={handlerOnSetToHomePageAI}
                    data-hasprompt={CHANGE_PROMPT_BOX_UI}
                    className="bg-black/0 cursor-pointer 
                        p-0
                        opacity-100

                        transition-all
                        duration-[0.15s]
                        
                        ml-0
                    "
                >
                    <img src={mcfAISoloIcon} className="h-auto w-8" />
                </Button>

                <Button className="bg-black/0 cursor-pointer p-0" title="Enviar arquivo(s)">
                    <IoAdd color="#000" size={32} />
                </Button>

                <div
                    ref={INPUT_PROPMT_ELEMENT}
                    contentEditable={!(!assistant && true) || (!onThread && true)}
                    translate="no"
                    data-hasprompt={CHANGE_PROMPT_BOX_UI}
                    data-allowed={!(!assistant && true) || onThread}
                    inputMode="text"
                    aria-disabled={!(!assistant && true) ? 'false' : 'true'}
                    // @ts-ignore
                    writingSuggestions={true}
                    // @ts-ignore
                    onInput={(e) => debounce(140, handlerOnInputPrompt, e.target?.innerText)}
                    onClick={() => setHomePage(false)}
                    className="
                            flex-1
                            max-w-[90%]
                            h-full
                            max-h-[300px]
                            bg-white 
                            rounded-[20px]
                            border-[1px] border-slate-200
                            text-black

                            pl-4                                
                            pr-16
                            py-2

                            cursor-text
                            
                            data-[hasprompt=true]:rounded-xl
                            data-[allowed=false]:cursor-not-allowed
                            data-[allowed=false]:bg-white/50
                        
                            overflow-y-auto
                            whitespace-pre-wrap
                            break-words

                            relative

                            transition-all
                            duration-[0.15s]
                        "
                >
                    <p>{' '}</p>
                </div>

                <Button
                    onClick={() => debounce(5, handleOnSetNewThreadPrompts, prompt)}
                    data-hasprompt={CHANGE_PROMPT_BOX_UI}
                    data-allowed={!onThread && true}
                    data-assistant={!(!assistant && true)}
                    disabled={!assistants || onThread && true}
                    title="Enviar"
                    className="
                        opacity-0
                        pointer-events-none
                        absolute 
                        right-2
                        bottom-4
                        transition-all
                        duration-[0.15s]
                        data-[hasprompt=true]:!opacity-100
                        data-[hasprompt=true]:right-12
                        data-[hasprompt=true]:pointer-events-auto
                        

                        data-[allowed=false]:cursor-default
                        data-[allowed=false]:animate-pulse
                        data-[assistant=false]:cursor-default
                    "
                >
                    <IoChevronUp size={32} data-onthread={onThread} className="data-[onthread=true]:animate-bounce" />
                </Button>

                <Button
                    onClick={() => debounce(225, handlerOnVoiceToPrompt)}
                    data-hasprompt={CHANGE_PROMPT_BOX_UI}
                    data-onrecord={onAudioStart}
                    title="Fale com a(o) assistente"
                    disabled={!assistant && true}
                    className="
                        opacity-100

                        mr-0

                        disabled:!opacity-50

                        data-[hasprompt=true]:-mr-10
                        data-[hasprompt=true]:!opacity-0
                        data-[hasprompt=true]:pointer-events-auto

                        data-[onrecord=true]:bg-red-400
                        data-[onrecord=true]:hover:bg-red-300
                        data-[onrecord=true]:hover:active:bg-red-200
                        data-[onrecord=true]:active:bg-red-300
                        data-[onrecord=true]:animate-bounce
                    "
                >
                    {/* <img src={mcfAIVoiceIcon} alt="" /> */}
                    <RiMic2Line size={32}
                        data-onrecord={onAudioStart}
                        className="!text-black data-[onrecord=true]:!text-white transition-colors duration-[0.10s]"
                    />
                </Button>
            </span>
        </div>
    </>
}