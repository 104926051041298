import { twMerge } from "tailwind-merge";

// Types
import { IButtonProps } from "./types";

// Styles
import "./styles.css";

export function Button({ children, className, ...props }: IButtonProps) {
    return (
        <button
            type="button"
            {...props}
            className={twMerge(
                "select-none transition-all will-change-auto duration-[0.15s] flex w-10 h-10 items-center justify-center rounded-xl p-2 bg-black/5 hover:bg-black/10 hover:active:bg-black/20 active:bg-black/20",
                className
            )}
        >
            {children}
        </button>
    )
}