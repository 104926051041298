import { InputNumber } from "primereact/inputnumber";
import { useEffect, useMemo, useState } from "react";

// Icons
import { BsBuildingUp, BsGraphUpArrow, BsHouseGear } from "react-icons/bs";
import { HiOutlineCalculator, HiOutlineColorSwatch } from "react-icons/hi";
import { IoListSharp, IoPricetagsOutline } from "react-icons/io5";
import { RiPriceTag2Line } from "react-icons/ri";
import { TbPigMoney } from "react-icons/tb";

// Types
import { IInvestorProps } from "./types";

// Models
import { IMRealStateProduct } from "~/models/RealEstateProduct";
import { IMRealEstateProductsHouse } from "~/models/RealEstateProductHouse";

export function Investor(props: IInvestorProps) {
    const [houseAmount, setHouseAmount] = useState(0)
    const [acquireAmount, setAcquireAmount] = useState(0)
    const [entryAmount, setEntryAmount] = useState(160000)
    const [stateAmount, setStateAmount] = useState(520)

    // AUX Variables
    const REP_PROJECT: IMRealStateProduct | null = useMemo(() => JSON.parse(sessionStorage.getItem('REP@PROJECT') || '{}') || null, [])
    const REP_BUILD_DATA: {
        houseAmount: number
        idealArea: number
        idealAreaBuild: number,
        AMOUNT_BY_METERS: string,
        VGV: {
            [key: number]: {
                vgv: number;
                house_area: number;
                terrain_area: number;
            }[];
        }
    } | null = useMemo(() => JSON.parse(sessionStorage.getItem('REP@BUILD_DATA') || '{}') || null, [])
    const REP_HOUSES:IMRealEstateProductsHouse[] | null = useMemo(() => JSON.parse(sessionStorage.getItem('REP@HOUSES') || '{}') || null, [])

    const VALOR_DA_AQUISICAO = 160000
    const VALOR_ENTRADA = 0    
    const ITBI_PERCENT = 2 / 100
    const AREA_DE_CADA_UNIDADE = 2300
    const CONSTRUCAO = 520 // VALOR DO ESTADO
    const ESCRITURA_REGISTRO_PERCENT  = 75 / 100
    const CERTIDAO_COPIAS_AUTENTICACOES_PERCENT  = 13 / 100
    const CUSTOS_FINANCIAVEIS = 152500.0
    const CUSTO_BRUTO_TERRENO = useMemo(() => (acquireAmount + entryAmount) - entryAmount,[acquireAmount, entryAmount])
    const ITBI = CUSTO_BRUTO_TERRENO * ITBI_PERCENT
    const CUSTO_TOTAL_CONSTRUCAO = useMemo(() => stateAmount * AREA_DE_CADA_UNIDADE,[stateAmount])
    const CUSTU_BRUTO_OBRA = CUSTO_TOTAL_CONSTRUCAO
    const INVESTIMENTO_BRUTO = CUSTO_BRUTO_TERRENO + CUSTU_BRUTO_OBRA + CUSTOS_FINANCIAVEIS
    const ESCRITURA_REGISTRO = INVESTIMENTO_BRUTO * ESCRITURA_REGISTRO_PERCENT
    const CERTIDAO_COPIAS_AUTENTICACOES = ITBI * CERTIDAO_COPIAS_AUTENTICACOES_PERCENT
    const CUSTO_TOTAL_TERRENO = useMemo(() => acquireAmount + CERTIDAO_COPIAS_AUTENTICACOES,[acquireAmount])
    const VALOR_DA_CAPTACAO = (INVESTIMENTO_BRUTO * 0.5) + INVESTIMENTO_BRUTO
    const LUCRO_DO_CONSTRUTOR = CUSTO_TOTAL_CONSTRUCAO * 0.1
    const VALOR_DE_CADA_CASA_10_PERCENT = useMemo(() => houseAmount * (9 / 100), [houseAmount])
    const VALOR_DE_CADA_CASA_10 = useMemo(() => houseAmount * 1.1, [houseAmount])
    const VALOR_DE_CADA_CASA_LUCRO_BRUTO = useMemo(() => houseAmount - (CUSTO_BRUTO_TERRENO + CUSTU_BRUTO_OBRA), [houseAmount])
    const VALOR_DE_CADA_CASA_LUCRO_BRUTO_PERCENT =  INVESTIMENTO_BRUTO - VALOR_DE_CADA_CASA_10_PERCENT
    const VALOR_DE_CADA_CASA_LUCRO_BRUTO_MULTIPLY = VALOR_DE_CADA_CASA_10 - INVESTIMENTO_BRUTO
    const CORRETAGEM_E_IMPOSTO = useMemo(() => (0.6 * houseAmount) + (0.5 * houseAmount),[houseAmount])
    const V3 = VALOR_DA_CAPTACAO;    
    const V4 = V3 - CUSTO_TOTAL_TERRENO    
    const S8 = V4 / 10
    const V5 = V4 -S8;
    const V6 = V5 -S8;
    const V7 = V6 -S8;
    const V8 = V7 -S8;
    const V9 = V8 -S8;
    const V10 = V9 -S8;
    const V11 = V10 -S8;
    const V12 = V11 -S8;
    const V13 = V12 -S8;
    const W13 = V13 * 0.108    
    const W3 = V3 * 0.108;
    const W15 = W3 + W13
    const Q8 = VALOR_DA_CAPTACAO * 0.19;
    const R8 = Q8 - W15

    useEffect(() => {
        if(!REP_BUILD_DATA || stateAmount < 0) return;

        setHouseAmount((REP_BUILD_DATA?.houseAmount || 0))
        setAcquireAmount((REP_BUILD_DATA?.idealArea || 0) * stateAmount)
    },[REP_BUILD_DATA,stateAmount])

    return (
        <div className="max-sm:!w-full h-full md:h-screen flex flex-col max-sm:items-center md:grid md:grid-cols-investor gap-2 md:ml-[106px] p-4"
            style={{
                width: 'calc(100vw - 106px)',                
            }}
        >

            <div className="max-sm:w-full h-auto flex flex-col max-sm:pt-16 max-sm:items-center max-sm:justify-center gap-4">
                <span className="font-bold text-xl">
                    {REP_PROJECT?.name || 'N/A'}
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <TbPigMoney size={25} />
                        <span className="font-semibold">Custos relacionados com a aquisição do Terreno</span>
                    </span>

                    <span className="w-full h-auto flex flex-col gap-3 p-4">
                        <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                            <span>Valor da Aquisição (financ.)</span>
                            <InputNumber
                                mode="currency" 
                                currency="BRL"
                                locale="pt-BR" 
                                minFractionDigits={2}
                                value={acquireAmount}
                                onValueChange={(e) => setAcquireAmount(e.value || 0)}
                            />
                            {/* <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        unitDisplay: 'long'
                                    }).format(acquireAmount)
                                }
                            </span> */}
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Entrada (volta no financ.)</span>
                            <InputNumber
                                mode="currency" 
                                currency="BRL"
                                locale="pt-BR" 
                                minFractionDigits={2}
                                value={entryAmount}
                                onValueChange={(e) => setEntryAmount(e.value || 0)}
                            />
                            {/* <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        unitDisplay: 'long'
                                    }).format(entryAmount)
                                }
                            </span> */}
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>ITBI</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        unitDisplay: 'long'
                                    }).format(ITBI_PERCENT)
                                }
                            </span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(ITBI)
                                }
                            </span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Escritura e registro (RGI)</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(ESCRITURA_REGISTRO)
                                }
                            </span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Certidões, Cópias e autenticações</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CERTIDAO_COPIAS_AUTENTICACOES)
                                }
                            </span>
                        </span>
                    </span>

                    <span className="w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4">
                        <span className="font-semibold">
                            {
                                Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    currencyDisplay: 'symbol'
                                }).format(ITBI + ESCRITURA_REGISTRO + CERTIDAO_COPIAS_AUTENTICACOES)
                            }
                        </span>
                        <span className="w-full flex flex-row items-center justify-end gap-5">
                            <span className="font-bold">Custos total do Terreno</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CUSTO_TOTAL_TERRENO)
                                }
                            </span>                            
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <BsBuildingUp size={25} />
                        <span className="font-semibold">Custos relacionados a obra</span>
                    </span>

                    <span className="w-full h-auto flex flex-col gap-3 p-4">
                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Número de Unidades</span>
                            <span className="font-semibold">1,00</span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span className="flex-1">Área do Terreno</span>
                            <span className="font-semibold flex-1 text-end">m²</span>
                            <span className="font-semibold flex-1 text-end">{Intl.NumberFormat('pt-BR', { style: 'unit', unit: 'meter',unitDisplay: 'short'}).format(REP_BUILD_DATA?.idealArea || 0)}</span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span className="flex-1">Área de cada unidade</span>
                            <span className="font-semibold flex-1 text-end">m²</span>
                            <span className="font-semibold flex-1 text-end">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'unit',
                                        unit: 'meter'
                                    }).format(AREA_DE_CADA_UNIDADE)
                                }
                            </span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                            <span className="flex-1">Construção</span>
                            <span className="font-semibold flex-1 text-end">m²</span>
                                <InputNumber
                                    mode="currency" 
                                    currency="BRL"
                                    locale="pt-BR" 
                                    minFractionDigits={2}
                                    value={stateAmount}
                                    onValueChange={(e) => setStateAmount(e.value || 0)}
                                />
                            {/* <span className="font-semibold flex-1 text-end">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(stateAmount)
                                }
                            </span> */}
                        </span>
                        <span className="w-full h-auto flex flex-row items-center justify-between gap-3">
                            <span>Lucro do Construtor (ENG + ARQ)</span>
                            <span className="font-semibold px-4 py-2 bg-[#39A887] text-white">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(LUCRO_DO_CONSTRUTOR)
                                }
                            </span>
                        </span>
                        <span className="w-full h-auto flex flex-row items-center justify-end gap-3">
                            <span className="font-semibold">R$ 0,00</span>
                        </span>
                    </span>

                    <span className="w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4">
                        <span className="w-full flex flex-row items-center justify-end gap-5">
                            <span className="font-bold">Custos total da construção</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CUSTO_TOTAL_CONSTRUCAO)
                                }
                            </span>                            
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <BsGraphUpArrow  size={25} />
                        <span className="font-semibold">Cálculo do Resultado Operacional </span>
                    </span>

                    <span className="w-full h-auto flex flex-col gap-3 p-4">
                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Custo bruto Terreno</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CUSTO_BRUTO_TERRENO)
                                }
                            </span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Custas Financiáveis</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CUSTOS_FINANCIAVEIS)
                                }
                            </span>
                        </span>

                        <span className="w-full h-auto flex flex-row items-center justify-between">
                            <span>Custo bruto Obra</span>
                            <span className="font-semibold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CUSTU_BRUTO_OBRA)
                                }
                            </span>
                        </span>
                    </span>

                    <span className="w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4">
                        <span className="w-full flex flex-row items-center justify-end gap-5">
                            <span className="font-bold">Investimento Bruto</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(INVESTIMENTO_BRUTO)
                                }
                            </span>                            
                        </span>
                        <span className="w-full flex flex-row items-center justify-center gap-5 bg-slate-500">
                            <span className="font-bold text-white">FUNDO É O SEU SÓCIO</span>
                        </span>
                        <span className="w-full flex flex-row items-center justify-end gap-5 bg-slate-400 text-white">
                            <span className="font-bold">VALOR DA CAPTAÇÃO (MCF)</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(VALOR_DA_CAPTACAO)
                                }
                            </span>                            
                        </span>
                        <span className="w-full flex flex-row items-center justify-end gap-5 bg-slate-300 text-white">
                            <span className="font-bold">Valor retirado do Bolso total</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(0)
                                }
                            </span>                            
                        </span>
                        <span className="w-full flex flex-row items-center justify-end gap-5">
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(VALOR_DA_CAPTACAO - INVESTIMENTO_BRUTO)
                                }
                            </span>
                        </span>
                        <span className="w-full flex flex-row items-center justify-end gap-5">
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format((VALOR_DA_CAPTACAO - INVESTIMENTO_BRUTO) / INVESTIMENTO_BRUTO)
                                }
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div className="max-sm:w-full h-auto flex flex-col max-sm:items-center max-sm:justify-center gap-4 py-[52px]">
                <span className="max-sm:w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <HiOutlineCalculator size={25} />
                        <span className="font-semibold">Cálculo da Receita</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 md:gap-12 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3 ">
                            <span className="w-full h-auto flex flex-col justify-between gap-4">
                                <span>Valor de Cada Casa (venda)</span>
                                <span className="font-semibold flex-1 flex">
                                    <InputNumber
                                        mode="currency" 
                                        currency="BRL"
                                        locale="pt-BR" 
                                        minFractionDigits={2}
                                        value={houseAmount}
                                        onValueChange={(e) => setHouseAmount(e.value || 0)}
                                    />

                                    {/* {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(houseAmount)
                                    } */}
                                </span>                            
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>A receber</span>
                                <span className="font-semibold flex-1 flex justify-end">
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(houseAmount)
                                    }
                                </span>
                            </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span className="w-full px-4 py-2 bg-red-500 text-center">10%</span>
                                <span className="mt-4">
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(VALOR_DE_CADA_CASA_10_PERCENT)
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span className="w-full px-4 py-2 bg-[#39A887] text-center">10%</span>
                                <span className="mt-4">
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(VALOR_DE_CADA_CASA_10)
                                    }
                                </span>
                            </span>
                        </span>
                    </span>

                    <span className="w-full md:min-w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4 ">
                        <span className="w-full flex flex-row items-center justify-end gap-5 max-sm:overflow-x-auto">
                            <span className="font-bold">Lucro BRUTO (Não)</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(VALOR_DE_CADA_CASA_LUCRO_BRUTO)                                    
                                }
                            </span>
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(VALOR_DE_CADA_CASA_LUCRO_BRUTO_PERCENT)                                    
                                }
                            </span>
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(VALOR_DE_CADA_CASA_LUCRO_BRUTO_MULTIPLY)
                                }
                            </span>
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <BsHouseGear  size={25} />
                        <span className="font-semibold">Imposto de Renda (LUCRO IMOBILIÁRIO)*</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="font-semibold flex-1 flex justify-end">0,15</span>                            
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>Pessoa Jurídica (PJ no lucro presumido)</span>
                                <span className="font-semibold flex-1 flex justify-end">0,06</span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.15 * (houseAmount - VALOR_DA_CAPTACAO ))
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.6 * houseAmount)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.15 * (VALOR_DE_CADA_CASA_LUCRO_BRUTO_PERCENT - CORRETAGEM_E_IMPOSTO))
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.6 * (VALOR_DE_CADA_CASA_10_PERCENT))
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span> 
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.15 * (VALOR_DE_CADA_CASA_LUCRO_BRUTO_PERCENT - CORRETAGEM_E_IMPOSTO))
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.3 * VALOR_DE_CADA_CASA_10)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                    </span>
                    <span className="w-full text-left text-xs px-4 py-2">
                        *diminuir valor do próximo terreno
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <HiOutlineColorSwatch   size={25} />
                        <span className="font-semibold">Corretagem*</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="flex-1">Corretor</span>                            
                                <span className="font-semibold flex-1 flex justify-end">5%</span>                            
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>Propaganda própia</span>
                                <span className="font-semibold flex-1 flex justify-end">1%</span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.5 * houseAmount)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (0.1 * VALOR_DE_CADA_CASA_LUCRO_BRUTO)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DE_CADA_CASA_10_PERCENT * 0.5)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DE_CADA_CASA_10_PERCENT * 0.1)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span> 
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DE_CADA_CASA_10 * 0.5)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DE_CADA_CASA_10 * 0.1)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                    </span>
                </span>

                <span className="w-full h-[400px] 
                        flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        shadow-md overflow-hidden
                        object-fit
                        bg-slate-200
                    "
                >
                    <img
                        // @ts-ignore
                        src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/${REP_PROJECT?.id || -1}/photo`}
                        alt="Imagem do projeto"  
                        className="max-w-full h-auto"
                    />
                </span>
            </div>
            <div className="max-sm:w-full h-auto flex flex-col max-sm:items-center max-sm:justify-center gap-4 py-[52px]">
                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <IoPricetagsOutline size={25} />
                        <span className="font-semibold">Valor final a receber</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="flex-1">Total Quitação</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>CUSTO DO DINHEIRO (JUROS) 90% DESSA LIVE VÃO FINANCIAR O SUCESSO DE 10%</span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span className="text-[#39A88E]">
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            houseAmount
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DA_CAPTACAO)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (R8)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                    </span>

                    <span className="w-full md:w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4 ">
                        <span className="w-full flex flex-row items-center justify-end gap-5 max-sm:overflow-x-auto">
                            <span className="font-bold">A Receber</span>                            
                            <span className="font-bold text-[#39A88E]">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(
                                        (houseAmount - VALOR_DA_CAPTACAO - R8)
                                    )
                                }
                            </span>
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <RiPriceTag2Line size={25} />
                        <span className="font-semibold">Custos Obra e financiamento</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="flex-1">Corretagem e Imposto</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>10  x Condomínio</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>10 Luz e água</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>IPTU anual</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span className="text-red-500">HABITE-SE + AVERBAÇÃO + ISS +INSS</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span>Custos terreno</span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (CORRETAGEM_E_IMPOSTO)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (10 * 600)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (10 * 110)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (3500)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (VALOR_DA_CAPTACAO * 0.15)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="font-semibold flex flex-col gap-2">
                                <span>
                                     {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(ITBI + ESCRITURA_REGISTRO + CERTIDAO_COPIAS_AUTENTICACOES)
                                    }
                                </span>
                            </span>
                        </span>
                    </span>

                    <span className="w-full md:w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4 ">
                        <span className="w-full flex flex-row items-center justify-end gap-5 max-sm:overflow-x-auto">
                            <span className="font-bold">Custo total desembolsado</span>                            
                            <span className="font-bold">
                                {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(CORRETAGEM_E_IMPOSTO + (ITBI + ESCRITURA_REGISTRO + CERTIDAO_COPIAS_AUTENTICACOES))
                                }
                            </span>
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <IoListSharp   size={25} />
                        <span className="font-semibold">RECEITA x DESPESAS</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="flex-1">Resultado Líquido</span>
                            </span>
                        </span>
                        <span className="flex flex-col justify-end gap-3">
                            <span className="font-semibold flex flex-col gap-2">
                                <span className="text-[#39A887]">
                                    {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            (houseAmount - VALOR_DA_CAPTACAO - R8)
                                        )
                                    }
                                </span>
                            </span>
                        </span>
                    </span>

                    <span className="w-full md:w-[90%] self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4 ">
                        <span className="w-full flex flex-row items-center justify-end gap-5 max-sm:overflow-x-auto">
                            <span className="font-bold">Resultado líquido</span>
                            <span className="font-bold text-[#39A887]">
                                 {
                                    Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        currencyDisplay: 'symbol'
                                    }).format(
                                        (houseAmount - VALOR_DA_CAPTACAO - R8)
                                    )
                                }
                            </span>
                        </span>
                    </span>
                </span>

                <span className="w-full h-auto flex flex-col justify-between gap-3
                        rounded-xl
                        border-slate-200 border-[1px]
                        bg-white shadow-md overflow-hidden
                    "
                >
                    <span className="w-full flex flex-row items-center gap-2 px-4 pt-4 pb-2 border-slate-200 border-b-[1px]">
                        <BsGraphUpArrow  size={25} />
                        <span className="font-semibold">Indicadores de Desempenho</span>
                    </span>

                    <span className="w-full h-auto flex flex-row flex-nowrap gap-4 p-4 max-sm:overflow-x-auto">
                        <span className="flex-1 flex flex-col gap-3">
                            <span className="w-full h-auto flex flex-row items-center justify-end gap-4">
                                <span className="flex-1">Valor total do Investimento</span>
                                <span className="flex justify-end font-semibold">R$ 0,00</span>
                            </span>

                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span className="flex-1 font-bold">Resultado líquido Mensal</span>
                                <span className="flex justify-end font-semibold text-[#39A887]">
                                     {
                                        Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            currencyDisplay: 'symbol'
                                        }).format(
                                            ((houseAmount - VALOR_DA_CAPTACAO - R8) / 12)
                                        )
                                    }
                                </span>
                            </span>
                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span className="flex-1">RENTABILIDADE ANUAL</span>
                                <span className="flex justify-end font-semibold text-[#39A887]">INFINITO</span>
                            </span>                           
                            <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                                <span className="flex-1">RENTALIDADADE MENSAL</span>
                                <span className="flex justify-end font-semibold text-[#39A887]">INFINITO</span>
                            </span>                           
                        </span>
                    </span>

                    <span className="w-full min-h-12 self-end h-auto flex flex-col items-end gap-2 bg-slate-200 p-4 ">                       
                    </span>
                </span>
            </div>
        </div>
    )
}