import { SelectItemOptionsType } from "primereact/selectitem";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";

// Icons
import { BsX } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";

// Styles
import "../../styles/index.css";

// Types
import { IEnvironment } from "~/hooks/BuildYourHouse/types";
import { IMunicipality, IState } from "~/models/Simulation/RequestQuote";
import { IBudget, IProject, IProjectFormData, IProjectsProps } from "../../type";

// Partials
import { External } from "~/pages/BuildYourHouse/Partials/Steps/External";
import { Interior } from "~/pages/BuildYourHouse/Partials/Steps/Interior";

// Services
import api from "~/services/api";

// Utils
import Toast from "~/utils/toast";

export function Modal({data, onRollback,onPushback, onClose}: IProjectsProps & {onClose: () => void}) {
    const [value, setValue] = useState(() => data?.description || '')
    const [environments, setEnvironments] = useState<IEnvironment[]>([])
    const [project, setProject] = useState<IProject | null>(null)
    const [municipalities, setMunicipalities] = useState<SelectItemOptionsType>();
    const [stateOptions, setStateOptions] = useState<SelectItemOptionsType>();
    const [isPeddingMunicipalities, setIsPeddingMunicipalities] = useState<boolean>(false);
    const [states, setStates] = useState<IState[]>([]);
    const [stateValue, setStateValue] = useState<number>(0);
    const [budget, setBudget] = useState<IBudget>({
        budget: 0,
        percent: 0,
        totalBudget: 0
    });
    const [footage, setFootage] = useState<number>(0);
    
    // AUX Variables
    const BYH_ABOUT_HOUSE_REF = useRef<HTMLLabelElement | null>(null)    
    const form = useForm({
        defaultValues:{
            description: '',
            state_id: 0,
            municipality: ''
        },
    })    
    const STATE_WATCHED = form.watch('state_id') as number;       
    const MUNICIPALITY = useMemo(() => data?.municipality, [data])
    const STATE = data?.state_id || STATE_WATCHED || form.getValues('state_id');
    

    const handleLoadState = useCallback(
    (value?: string) => {
      api
        .get<IState[]>('builders/states')
        .then((response) => {
            
            const data: SelectItemOptionsType = response.data.map(
                (stateData) => ({
                    value: stateData.id,
                    label: stateData.nome,
                })
            );

            setStates(response.data);
            setStateOptions(() => data);
        });      
    },
    []
    );

    const handleLoadMunicipalitys = useCallback((stateChoosed: number) => {
        setIsPeddingMunicipalities(true)
        api
            .get<IMunicipality[]>(`municipalities/state/${stateChoosed}`)
            .then((response): any => {

                const MUNICIPALITY_STATEMENT: SelectItemOptionsType = response.data.map(
                    (municipalityData) => ({
                        value: municipalityData.name,
                        label: municipalityData.name,
                    })
                );

                setMunicipalities(MUNICIPALITY_STATEMENT);

                if (MUNICIPALITY_STATEMENT) {
                    const city = MUNICIPALITY_STATEMENT.find(
                    (municipalityData) => municipalityData.value === data?.municipality
                    );

                    if(!city) return;

                    form.setValue('municipality',city.value);
                }
            })
            .finally( () => {
                setIsPeddingMunicipalities(false);
            });  
    }, [])

    useEffect(() => {
        if(states.length > 0) return;

        handleLoadState();
    }, []);

    useEffect(() => {

        if(environments.length > 0 || !project?.id) return;

        Promise.all([
            api.get<IEnvironment[]>(
                `/builders/service-orders/${project.id}/budget/environments`
            ),
        ]).then(resolvers => {
            setEnvironments(resolvers[0].data);            
        })        
    },[project])

    useEffect(() => {
        if(!data) return;

        setProject(data)
        setValue(data.description)

        handleLoadMunicipalitys(data?.state_id || -1)
    },[data])

    useEffect(() => {
        if(states.length <= 0) return;

        const STATE_VALUE = (states.find((state: IState) => state?.id === data?.state_id) as IState | undefined)?.valor || 0;
        
        setStateValue(STATE_VALUE);
    }, [states])

    async function onSubmit(data: IProjectFormData){
        try {
            console.table({data, f: form.getValues()})
            if(!data) throw new Error('Não foi possivel criar o projeto');            

            if(!data.description || data.description.length <= 0) throw new Error('Informe uma descrição');

            const URL = `builders/build-your-house${project ? `/${project.id}` : ''}`;
            const METHOD = !project ? 'post' : 'put';
            const STATUS_CODE = !project ? 201 : 200;
            const MESSAGE_REQUEST = !project ? 'Criado com sucesso' : 'Atualizado com sucesso';
            const MESSAGE_REQUEST_ERROR = !project ? 'Não foi possivel criar o projeto' : 'Não foi possivel atualizar o projeto';

            const response = await api[METHOD]<IProject>(URL, {
                description: data.description,
                // @ts-ignore
                state_id: parseInt(data.state_id || form.getValues('state_id'),10),
                // @ts-ignore
                municipality : data.municipality
            });

            if(response.status !== STATUS_CODE) throw new Error(MESSAGE_REQUEST_ERROR);

            Toast.fire({
                icon: 'success',
                text: MESSAGE_REQUEST
            });

            if(onPushback && typeof onPushback === 'function' && METHOD === 'post') onPushback(response.data);

            if(STATUS_CODE === 201) setProject(response.data);
        } catch (error: any) {
            Toast.fire({
                icon: 'warning',
                text: error?.message || 'Houve um erro'
            });
        }
    }

    function handlerGetBudget(percent: number = -1, totalBudget: number = -1, budget: number = -1){
        try {
            if(percent <= -1 && totalBudget <= -1 && budget <= -1) throw new Error('The budget is missing');

            setBudget({
                percent,
                budget,
                totalBudget
            })
        } catch (error) {
            // do anything
        }
    }

    function handleCloseModal(){
        try {

            setProject(null)
            setValue('')
            
            sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_INTERIOR')
            sessionStorage.removeItem('MCF_BUY@ALREADY_INTERIOR_ENVIRONMENTS')
            localStorage.removeItem('@HarveyClient:OSBuilder');
            sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_EXTERIOR')
            
            if(onClose && typeof onClose === 'function') onClose()
        } catch (error) {
            // do anything
        }
    }

    return (
        <div
            data-showmodal={false}
            className="
                w-full h-screen
                z-50
                overlay-motion
                absolute
                top-0
                left-0
                modal-overlay
                flex flex-row 
                items-center justify-center
            "
        >
            <div
                data-hasBYHId={Boolean((project && true))}
                className="content-motion
                    transitions-motion
                    max-sm:w-full md:w-2/3
                    data-[hasBYHId=true]:md:h-[95vh]
                    data-[hasBYHId=true]:max-sm:h-full
                    overflow-y-auto
                    rounded-[16px] bg-white 
                    shadow-xl 
                    md:px-[50px] 
                    max-sm:px-[15px]
                    py-[70px]
                    flex flex-col
                    gap-10
                "
            >
                <div className="w-full flex flex-row items-center justify-between">
                    <span className="text-[#414142] text-2xl font-semibold leading-6">
                        <span data-hasProject={project && true} className="flex data-[hasProject=true]:hidden">
                            Adicionar Casa
                        </span>
                        <span data-hasProject={project && true} className="hidden data-[hasProject=true]:flex">
                            Atualizar projeto
                        </span>
                    </span>
                    <span title="Fechar" onClick={handleCloseModal} className="cursor-pointer">
                        <BsX className="md:w-[24px] md:h-[24px] max-sm:w-[20px] max-sm:h-[20px]"/>
                    </span>
                </div>

                <form 
                    name="form-byh" 
                    id="form-byh" 
                    onSubmit={form.handleSubmit(onSubmit, (d) => console.table(d))} 
                    className="w-full flex flex-col gap-[40px]"
                >
                    <span className="w-full flex flex-col gap-4">
                        <span className="text-sm text-[#707070] font-medium leading-4">Descrição</span>
                        <textarea
                            id="description"                                    
                            {...form.register('description')}
                            required={true}
                            minLength={1}
                            placeholder="Uma descrição da casa..."
                            defaultValue={project?.description}
                            value={project?.description}
                            className="
                                outline-none
                                border-b-[1px] border-[#B8B9BA]
                                resize-y
                                min-h-[30px]
                                md:max-h-[300px]
                                max-sm:max-h-[200px]
                                placeholder:text-[#B8B9BA]
                                placeholder:text-sm
                                placeholder:font-normal
                            "
                        >
                        </textarea>
                        <span className="text-sm text-red-500 font-light">
                            {form.getFieldState('description').error?.message}
                        </span>
                    </span>

                    <span className="w-full flex flex-col md:flex-row md:flex-nowrap gap-4 -mt-[30px]">
                        <span className="w-full flex flex-col gap-4 z-[999]">
                            <span className="text-sm text-[#707070] font-medium leading-4">Estado</span>
                            <select
                                {...form.register('state_id')}
                                onChange={(e) => {
                                    // @ts-ignore
                                    const STATE_PARSED = parseInt(e.target.value, 10)
                                    const STATE_VALUE = (states.find((state: IState) => state?.id === STATE_PARSED) as IState | undefined)?.valor || 0
                                    
                                    setStateValue(STATE_VALUE)

                                    handleLoadMunicipalitys(parseInt(e.target.selectedOptions[0].value, 10))
                                }}
                                placeholder="Escolha um estado"
                                className="
                                    outline-none
                                    border-b-[1px] border-[#B8B9BA]
                                    resize-y
                                    min-h-[30px]
                                    md:max-h-[300px]
                                    max-sm:max-h-[200px]
                                    placeholder:text-[#B8B9BA]
                                    placeholder:text-sm
                                    placeholder:font-normal
                                "
                            >
                                <option 
                                    value="-1" 
                                    data-showme={!STATE && true} 
                                    className="data-[showme=true]:flex hidden"
                                >
                                    Selecione um estado
                                </option>
                                {
                                    stateOptions?.map((op: any, index) => (
                                        <option 
                                            selected={op?.value === STATE}
                                            key={index}
                                            value={op.value as unknown as string}
                                        >
                                            {
                                                op?.label || ''
                                            }
                                        </option>
                                    ))
                                }
                            </select>
                            <span className="text-sm text-red-500 font-light">
                                {form.getFieldState('state_id').error?.message}
                            </span>
                        </span>
                        <span className="w-full flex flex-col gap-4 z-[999]">
                            <span className="text-sm text-[#707070] font-medium leading-4">Municipios</span>
                            <select
                                onChange={(e) => form.setValue('municipality',e.target.selectedOptions[0].value)}
                                placeholder="Escolha um municipio"
                                disabled={isPeddingMunicipalities}
                                className="
                                    outline-none
                                    border-b-[1px] border-[#B8B9BA]
                                    resize-y
                                    min-h-[30px]
                                    md:max-h-[300px]
                                    max-sm:max-h-[200px]
                                    placeholder:text-[#B8B9BA]
                                    placeholder:text-sm
                                    placeholder:font-normal
                                    disabled:cursor-not-allowed
                                "
                            >
                                <option 
                                    value="-1" 
                                    data-showme={isPeddingMunicipalities && states.length > 0} 
                                    className="data-[showme=true]:flex hidden"
                                >
                                    Selecione um municipio
                                </option>
                                {
                                    municipalities?.map((op: any, index) => (
                                        <option  
                                            selected={op.value === MUNICIPALITY}
                                            key={index} 
                                            value={op.value as unknown as string}
                                        >
                                            {
                                                op?.value || ''
                                            }
                                        </option>
                                    ))
                                }
                            </select>
                            <span className="text-sm text-red-500 font-light">
                                {form.getFieldState('municipality').error?.message}
                            </span>
                        </span>
                    </span>
                </form>

                <div className="-mt-10">
                    <button
                        disabled={form.formState.isSubmitting && true}
                        title="Finalizar projeto"
                        type="submit"
                        form="form-byh"
                        className="
                            max-sm:w-full max-sm:h-[50px] 
                            md:w-[213px] md:h-[56px] 
                            bg-[#39A887] 
                            active:bg-[#079269]
                            hover:bg-[#24a07b]
                            md:hover:active:bg-[#317963]
                            text-white
                            max-sm:rounded-[13px] 
                            md:rounded-[26px] 
                            px-[33px]
                            flex flex-row 
                            items-center 
                            justify-center 
                            gap-1
                            transition-all duration-[0.30s]
                        "
                    >
                        <span
                            data-onsubmit={form.formState.isSubmitting && true} 
                            className="flex data-[onsubmit=true]:hidden"
                        > 
                            <span 
                                data-hasproject={!!project} 
                                className="hidden data-[hasproject=false]:flex"
                            >
                                Adicionar casa 
                            </span>
                            <span
                                data-hasproject={!!project} 
                                className="hidden data-[hasproject=true]:flex"
                            >
                                Atualizar casa 
                            </span>                                    
                        </span>
                        <span 
                            data-onsubmit={form.formState.isSubmitting && true}
                            className="hidden data-[onsubmit=true]:flex"
                        >
                            <CgSpinner className="text-white animate-spin"/>
                        </span>
                    </button>
                </div>

                <div
                    data-hasBYHId={Boolean((project && true))} 
                    className="
                        w-full
                        flex-1
                        data-[hasBYHId=false]:hidden 
                        flex flex-col gap-4
                    "
                >
                    <input type="radio" name="buil-your-house-steps" id="byh-exterior" className="peer/BYHStepsExterior hidden" defaultChecked={true} />        
                    <input type="radio" name="buil-your-house-steps" id="byh-interior" className="peer/BYHStepsInterior hidden" />
                
                    <span
                        className="
                            flex flex-row 
                            items-center justify-between 
                            gap-2

                            peer-checked/BYHStepsExterior:[&_.byh-exterior]:text-[#079269]
                            peer-checked/BYHStepsExterior:[&_.byh-exterior]:bg-[#E1F1ED]

                            peer-checked/BYHStepsInterior:[&_.byh-interior]:text-[#079269]
                            peer-checked/BYHStepsInterior:[&_.byh-interior]:bg-[#E1F1ED]
                        "
                    >
                        <span
                            className="
                                flex flex-row 
                                items-center justify-start 
                                gap-2
                            "
                        >
                            <label htmlFor="byh-exterior" className="byh-exterior w-[6.81rem] h-[1.68rem] cursor-pointer flex flex-row items-center justify-center hover:bg-[#E1F1ED] text-[#7A797D] text-sm font-medium px-[5px] rounded-[30px] transition-all duration-[0.30s]">Acabamento</label>
                            <label ref={BYH_ABOUT_HOUSE_REF} htmlFor="byh-interior" className="byh-interior w-[6.81rem] h-[1.68rem] cursor-pointer flex flex-row items-center justify-center hover:bg-[#E1F1ED] text-[#7A797D] text-sm font-medium px-[5px] rounded-[30px] transition-all duration-[0.30s]">Sobre a casa</label>
                        </span>
                        <span
                            className="
                                w-auto
                                max-w-[50%]
                                h-auto 
                                flex
                                flex-row 
                                flex-nowrap 
                                items-center justify-start
                                gap-2
                                pt-2
                                pb-2
                                pl-10
                                pr-10
                                rounded-xl
                                border-[#079269]
                                bg-[#E1F1ED]
                                text-md font-semibold
                            "
                        >
                            <span className="font-medium">Metragem: </span>
                            {
                                Intl.NumberFormat('pt-BR', {
                                    style: 'unit',
                                    unit: 'meter',
                                    unitDisplay: 'short'
                                }).format(footage)
                            }
                        </span>
                        <span
                            className="
                                w-auto
                                max-w-[50%]
                                h-auto 
                                flex
                                flex-row 
                                flex-nowrap 
                                items-center justify-start
                                gap-2
                                pt-2
                                pb-2
                                pl-10
                                pr-10
                                rounded-xl
                                border-[#079269]
                                bg-[#E1F1ED]
                                text-md font-semibold
                            "
                        >
                            <span className="font-medium">Total: </span>
                            {
                                Number(budget.totalBudget).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                })
                            }
                        </span>
                    </span>
                
                    <span
                        data-hasBYHId={Boolean(project?.id && true)}
                        className="
                            w-full
                            peer-checked/BYHStepsExterior:h-full
                            peer-checked/BYHStepsInterior:h-full

                            rounded-[15px]

                            transitions-motion

                            data-[hasBYHId=true]:[&_.byh-exterior-step-content]:!left-0
                            data-[hasBYHId=true]:[&_.byh-exterior-step-content]:!opacity-100
                            data-[hasBYHId=true]:peer-checked/BYHStepsInterior:[&_.byh-interior-step-content]:!left-0
                            data-[hasBYHId=true]:peer-checked/BYHStepsInterior:[&_.byh-interior-step-content]:!opacity-100

                            data-[hasBYHId=true]:peer-checked/BYHStepsInterior:[&_.byh-exterior-step-content]:!-left-full
                            data-[hasBYHId=true]:peer-checked/BYHStepsInterior:[&_.byh-exterior-step-content]:!opacity-0

                            relative
                        "
                    >
                        {
                            project && (
                                <span
                                    data-hasBYHId={Boolean(project?.id && true)}
                                    className="
                                        byh-exterior-step-content 
                                        w-full h-full 
                                        flex p-4 
                                        flex-col gap-4
                                        transitions-motion
                                        absolute
                                        opacity-0
                                        -left-full
                                    "
                                >
                                    <External 
                                        externalEnvironments={environments}
                                        showNavigation={true}
                                        stepType={'acabamento'}
                                        generalID={project?.id || -1}
                                        hasOS={false}
                                    

                                        urlEnvinromentCallback={`builders/build-your-house/${project?.id || -1}/environments`}
                                        urlParametersCallback={`builders/build-your-house/${project?.id || -1}/parameters`}
                                        onFinishedCallback={() => BYH_ABOUT_HOUSE_REF?.current?.click()}
                                    />
                                </span>
                            )
                        }
                        {
                            project && (
                                <span 
                                    data-hasBYHId={Boolean(project?.id && true)}
                                    className="
                                        byh-interior-step-content 
                                        transitions-motion
                                        w-full h-full
                                        p-4 
                                        flex-col gap-14
                                        absolute
                                        opacity-0
                                        -left-full
                                    "
                                >
                                    <Interior 
                                        generalID={project?.id || -1}
                                        isOS={false}
                                        showNavigation={true}
                                        stepType={"quarto"}
                                        terrainAmount={stateValue}
                                    
                                        urlEnvinromentCallback={`builders/build-your-house/${project?.id || -1}/environments`}
                                        urlParametersCallback={`builders/build-your-house/${project?.id || -1}/parameters`}
                                        urlRequestCallback={`${process.env.REACT_APP_PREFIX_ROUTE}/requests`}

                                        onSelectedStep={() => ({})}
                                        onOptionsSelected={() => ({})}
                                        onCompletedStatus={() => ({})}
                                        onBudgetCalculation={handlerGetBudget}
                                        onFootageCalculation={setFootage}
                                    />
                                </span>
                            )
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}