import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Partials
import { House } from './Partials/House';
import { Study } from './Partials/Study';

// Types
import { ILeonardoAIResponse, IParams, TGeneratedImages } from './types';

// Components
import UserDefault from '~/components/UserDefault';

// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';

// Services
import api from '~/services/api';

export function Project(): JSX.Element {
  const { id: projectID } = useParams<IParams>();
  const [realEstateProduct, setRealEstateProduct] = useState<IMRealStateProduct | null>(null);
  const [realEstateHouseAmount, setRealEstateHouseAmount] = useState(0);
  const [realStateHouseidealArea, setRealStateHouseidealArea] = useState(0);
  const [realStateHouseidealAreaBuild, setRealStateHouseidealAreaBuild] = useState(0);
  const [generateImages, setGenerateImages] = useState<TGeneratedImages[]>([]);
  const [onGenerateImages, setOnGenerateImages] = useState<boolean>(false);

  /**
   * Set or Get on localStorage the images from Leonardo AI
   * @param generateImagesData
   * @returns true or false
   */
  function onLeonardoAIGeneratedImagesCache(generateImagesData: TGeneratedImages[] = []){
    try {
      const nameCache = `REP_PROJECT@${projectID}`

      const generateImagesCached: TGeneratedImages[] = JSON.parse(localStorage.getItem(nameCache) || '[]')

      if(generateImagesData.length <= 0 && generateImagesCached && generateImagesCached.length > 0) {
        setGenerateImages(generateImagesCached)
        return false
      }

      if(generateImagesData.length > 0) localStorage.setItem(nameCache, JSON.stringify(generateImagesData))

      console.log('onLeonardoAIGeneratedImagesCache')

      return true
    } catch (error) {
      // do anything
    }
  }

  function handlerSetRealEstateHouseAmount(value: number = -1){
    try {
      if(value <= -1) return

      setRealEstateHouseAmount(value)

      console.log('handlerOnRealEstateHouseAmount: ',value)
    } catch (error) {
      // do anything
    }
  }

  function handlerSetRealStateHouseidealArea(value: number = -1){
    try {
      if(value <= -1) return

      setRealStateHouseidealArea(value)

      console.log('handlerOnRealStateHouseidealArea: ',value)
    } catch (error) {
      // do anything
    }
  }

  function handlerSetRealStateHouseidealAreaBuild(value: number = -1){
    try {
      if(value <= -1) return

      setRealStateHouseidealAreaBuild(value)

      console.log('handlerOnRealStateHouseidealAreaBuild: ',value)
    } catch (error) {
      // do anything
    }
  }

  /**
   * Send all images from Leonardo AI to Server
   * - This function work with recursivy strategie
   * @link https://pt.wikipedia.org/wiki/Recursividade
   * @param generateImagesData The array of images from Leaonardo AI
   * @param start The point of start, default is -1
   *
   * @deprecated this function will be deprecated, not use
   * @returns void
   */
  async function handleSetREPGeneratedImages(generateImagesData:TGeneratedImages[]= [], start = -1){
    try {
      if(start <=  -1 || generateImagesData.length <= 0) throw new Error('The data is not valid')

      if(start >= generateImagesData.length) return

      console.table(generateImagesData[start])
      console.log('handleSetREPGeneratedImages@Started in ~ ', start)

      const position = start == 0 ? 0 : start - 1

      const generateImageRecovey = await api.get<Blob>(generateImagesData[position].url, {
        headers: {
          ['Authorization']: null,
          ['authorization']: null,
          ['X-Sentry-Token']: null,
          // ['Accept']: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
          // ['Accept-Encodig']: 'gzip, deflate, br, zstd',
          // ['User-Agent']: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36 Edg/123.0.0.0',
          // ['Sec-Fetch-Mode']: 'navigate',
          // ['Sec-Fetch-Dest']: 'document',
          // ['Sec-Fetch-Site']: 'none',
          // ['Sec-Fetch-User']: '?1',
          // ['Upgrade-Insecure-Requests']: 1
        },
        responseType: 'blob'
      })
      .catch((rejects: AxiosError) => {
        // console.table(rejects.config)
        console.log(rejects.config.url)
        console.warn(`The actual iteration ${start} have errors: ${rejects?.message || ''} / ${rejects.response?.status}(${rejects.response?.statusText})`)
      })

      if(generateImageRecovey) {
        const formData = new FormData()

        // @ts-ignore
        formData.set('image_generated', new File([generateImageRecovey.data || ''],`${generateImagesData[position].id}.jpg`,) || '')
        formData.set('image_generated_id', generateImagesData[position].id)
        formData.set('rep_id', projectID)

        const response = await api.post(`builders/real-estate-products/ai/generated/create`, formData)

        if(response?.status !== 201) return
      }

      if(start >= 0 && start < generateImagesData.length) {
        await handleSetREPGeneratedImages(generateImagesData,start + 1)
        return
      }

      return await handleGetREPGeneratedImages()
    } catch (error) {
      console.table(error)
    }
  }

  /**
   * Get all imagens from Server, storaged by Webhook external service
   * @returns void
   */
  const handleGetREPGeneratedImages = useCallback(async () => {
    try {

      if(realEstateProduct && realEstateProduct.images.length > 0) {
        setGenerateImages(realEstateProduct.images.map(d => ({
          id: d.key,
          url: d.image_url || ''
        })))

        setOnGenerateImages(false)

        return
      }

      const response = await api.get<IMRealStateProduct>(`builders/real-estate-products/${projectID}`)

      if(response.status !== 200) throw new Error(`The request to recovery all images from Leonardo AI have some error - ${response.status}(${response.statusText})`)

      const dataParsed: TGeneratedImages[] = response.data.images?.map(file => ({
        id: file.key,
        url: file.image_url || ''
      }))

      if(response.data.images.length > 0) {
        onLeonardoAIGeneratedImagesCache(dataParsed)
      }

      if(generateImages.length <= 0) {
        setGenerateImages(dataParsed)
      }

      setOnGenerateImages(false)
    } catch (error) {
      setOnGenerateImages(false)
    }
  }, [generateImages])

  async function handleSetUpdateREPProject(generateID: string | null){
    try {
      if(!realEstateProduct || !generateID || generateID.trim() === '' || typeof generateID !== 'string' || generateID.length <= 0) return

      await api.post(`builders/real-estate-products/${realEstateProduct.id}`, {
        generation_id: generateID
      })
    } catch (error) {
      // do anything
    }
  }

  /**
   * Send the params to Leoarnado AI, and set to backend all imagens generated
   * @returns void
   */
  async function handlerSetLeonardoAIImages(){
    try {
      setOnGenerateImages(true)

      const timeoutIDRecovery: NodeJS.Timeout | null = sessionStorage.getItem('BUILDER@LEONARDO_AI_TIMEOUTID') as NodeJS.Timeout | null

      if(timeoutIDRecovery) clearTimeout(timeoutIDRecovery)

      if(realEstateProduct && realEstateProduct.generation_id) throw new Error('The REP already generated images')

      // @ts-ignore
      if (realEstateProduct?.houses?.length <= 0) throw new Error(`The houses data is not valid - ${realEstateProduct?.houses?.length}`);

      if ( realEstateHouseAmount <= 0 || realStateHouseidealArea <= 0 || realStateHouseidealAreaBuild <= 0) {
        setOnGenerateImages(false)
        return
      }

      if(onLeonardoAIGeneratedImagesCache() === false) throw new Error(`The cached images already`);

      const houseRooms = realEstateProduct?.houses?.reduce((a,b) => {
        const x = a.bathrooms + b.bathrooms
        const z = a.bedrooms + b.bedrooms

        const bathrooms = Math.round((x <= 0 ? 1 : x) / (realEstateProduct?.houses?.length || 1))
        const bedrooms = Math.round((z <= 0 ? 1 : z) / (realEstateProduct?.houses?.length || 1))

        return {
          bathrooms,
          bedrooms,
        }
      },{bathrooms: 0, bedrooms: 0})

      const prompt = `A stunning one-story house in Brazil, boasting ${Math.round(
        realStateHouseidealAreaBuild
      )} square meters of construction, ${houseRooms?.bedrooms || 1} bedrooms, ${houseRooms?.bathrooms || 1} bathrooms. Priced at ${realEstateHouseAmount.toLocaleString('pt-BR', {
        currency: 'BRL',
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}, Brazilian reais, this house has an avarege finishing standard`;

      console.warn(`Leonardo AI ~ ${prompt}`)

      const responseGenerated = await fetch(process.env.REACT_APP_LEONARDO_AI_API_URL || '', 
        {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_LEONARDO_AI_API_KEY || ''}`
        },
        body: JSON.stringify({
          prompt,
          height: 1024,
          width: 1024,
          alchemy: true,
          highResolution: true,
          photoReal: true,
          presetStyle: 'CREATIVE',
          num_images: 3
        })
      })
           
      // const responseGenerated = await api.post<ILeonardoAIResponse>(
      //     process.env.REACT_APP_LEONARDO_AI_API_URL || '',
      //     {
      //       prompt,
      //       height: 1024,
      //       width: 1024,
      //       alchemy: true,
      //       highResolution: true,
      //       photoReal: true,
      //       presetStyle: 'CREATIVE',
      //       num_images: 3
      //     },
      //     {
      //       baseURL: '',
      //       headers: {
      //         accept: 'application/json',
      //         ['authorization']: `Bearer ${
      //           process.env.REACT_APP_LEONARDO_AI_API_KEY || ''
      //         }`,
      //       },
      // })

      if(responseGenerated.status !== 200) throw new Error(`The generation of images do not work with success - ${responseGenerated.status}`);

      const GENERATED_DATA: ILeonardoAIResponse = await responseGenerated.json();

      await handleSetUpdateREPProject(GENERATED_DATA.sdGenerationJob.generationId)

      console.warn(new Date().toLocaleString('pt-BR'))
      
      const timeoutID = setTimeout(() => {
        console.warn(new Date().toLocaleString('pt-BR'))
        handleGetREPGeneratedImages()
      }, 2 * 60000)

      sessionStorage.setItem('BUILDER@LEONARDO_AI_TIMEOUTID', `${timeoutID}`)
    } catch (error: any) {
      console.warn(`Something are wrong with images from Leonardo AI @ ${error?.message || ''}`)      

      handleGetREPGeneratedImages()
    }
  }

  // RECOVERY REP AND RECOVERY study Ideal Real Estate and Indicator
  useEffect(() => {
    if (!projectID) return;

    Promise.all([
      api.get<IMRealStateProduct>(`builders/real-estate-products/${projectID}`),
      api.get<any>(`builders/real-estate-products/${projectID}/terrains`)
    ]).then(resolvers => {
      if(resolvers[0].status !== 200 || resolvers[1].status !== 200 ) return;

      setRealEstateProduct(resolvers[0].data);
      sessionStorage.setItem('REP@PROJECT', JSON.stringify(resolvers[0].data))
    }).catch(_rejects => ({}))
  }, [projectID]);  

  // GET ALL IMAGEM FROM LEONARDO AI AND PUT IN A ARRAY OF URL's
  useEffect(() => {
    handlerSetLeonardoAIImages()
  }, [
    realEstateHouseAmount,
    realStateHouseidealArea,
    realStateHouseidealAreaBuild,
  ]);

  return (
    <div className="flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t h-screen max-sm:!p-[none]">
      <UserDefault home />
      <div className="container max-sm:!w-screen max-sm:!p-[0rem] max-sm:!max-w-screen mx-auto flex flex-col items-start justify-start gap-rep60">
        <House realEstateProduct={realEstateProduct} projectID={projectID} />

        <Study
          realEstateProduct={realEstateProduct}
          projectID={projectID}
          onRealEstateHouseAmount={handlerSetRealEstateHouseAmount}
          onRealStateHouseidealArea={handlerSetRealStateHouseidealArea}
          onRealStateHouseidealAreaBuild={handlerSetRealStateHouseidealAreaBuild}
        />

        {
          // SKELETON FROM LEONARDO AI IMAGES
        }
        <div data-showme={ onGenerateImages && true} className="w-full h-[585px] hidden data-[showme=true]:flex flex-col gap-[21px] mb-10 p-2">
          <div className="flex flex-col gap-4">
            <span className="w-1/2 h-8 rounded-md text-xl bg-gray-300 animate-pulse">
            </span>
            <span className="w-1/3 h-8 rounded-md bg-gray-300 animate-pulse font-bold text-3xl">
            </span>
          </div>

          <div className="flex flex-col md:flex-row mb-10 w-full h-full justify-start md:gap-x-[21px] gap-y-[14px] items-star">
            <div
              className="
                object-fill
                flex-1
                md:min-w-[50%]
                h-[476px]
                overflow-hidden
                rounded-[20px]
                bg-gray-300
                animate-pulse
              "
            >
            </div>
            <div className="w-full flex-1 flex flex-col gap-x-[21px] gap-y-[14px]">
              {Array(2).fill(null).map((_skeleton, index) => (
                <div key={index}
                  className='
                    object-fill
                    flex-1
                    overflow-hidden
                    rounded-[20px]
                    bg-gray-300
                    animate-pulse
                  '
                >
                </div>
              ))}
            </div>
          </div>
        </div>

        {
          // IMAGES FROM LEONARDO AI
        }
        <div
          data-showme={generateImages.length > 0 && !onGenerateImages && true}
          className="w-full h-[585px] hidden data-[showme=true]:flex flex-col gap-[21px] mb-10"
        >
          <div className="flex flex-col">
            <span className="text-xl text-gray-700">
              Com base nos dados carregados,
            </span>
            <span className="text-[#39A887] font-bold text-3xl">
              criamos a sua fachada ideal
            </span>
          </div>

          <div className="w-full mb-10 flex flex-col md:flex-row justify-start items-start gap-x-[21px] gap-y-[14px] ">
            <div className="object-fill flex-1 min-w-[50%] h-[476px] overflow-hidden rounded-[20px] max-sm:w-full max-sm:h-[200px]">
              <img
                key={generateImages[0]?.id}
                src={generateImages[0]?.url}
              />
            </div>
            <div className="flex-1 flex flex-col md:gap-x-[21px] gap-y-[14px]">
              {generateImages?.slice(1, generateImages.length).map((image, index) => (
                <div key={image.id}
                  className='
                    w-full
                    !max-h-[230px]
                    object-fill
                    overflow-hidden
                    rounded-[20px]
                  '
                >
                  <img
                    key={index}
                    className="rounded-lg"
                    src={image.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
