import { twMerge } from "tailwind-merge";
import { ILabelProps } from "./types";

export function Label({ children, className, ...props }: ILabelProps) {
    return (
        <label
            {...props}
            className={twMerge(
                "transition-all duration-[0.15s] flex w-10 h-10 items-center justify-center rounded-xl p-2 bg-black/5 hover:bg-black/10 hover:active:bg-black/20 active:bg-black/20",
                className
            )}
        >
            {children}
        </label>
    )
}