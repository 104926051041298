import { useCallback, useMemo, useState } from "react";

// Types
import { ISpeechProps } from "./types";

export function useSpeech({
    speakLang = 'pt-BR', speechLang = 'pt-BR',
    speakPitch = 1,
    speakRate = 1,
    speakVolume = 1,
    speechContinuous = true,
    speechInterimResults = true,
    speechMaxAlternatives = 1, onSpeechRecognitionCallBackFn,
    onSpeechRecognitionRoolBackFn,
    onSpeechSynthesisCallBackFn, onSpeechSynthesisRoolBackFn, }: ISpeechProps) {

    const [onAudioStart, setOnAudioStart] = useState<boolean>(false) // Fired when the user agent has started to capture audio.
    const [onEnd, setOnEnd] = useState<boolean>(false) // Fired when the speech recognition service has disconnected.
    const [onError, setOnError] = useState<boolean>(false) // Fired when a speech recognition error occurs.
    const [onSpeechNoMatch, setSpeechOnNoMatch] = useState<boolean>(false) // Fired when the speech recognition service returns a final result with no significant recognition.
    const [onSpeechSound, setSpeechOnSound] = useState<boolean>(false) // Fired when any sound — recognizable speech or not — has been detected.
    const [onSpeech, setOnSpeech] = useState<boolean>(false) // Fired when sound that is recognized by the speech recognition service as speech has been detected.
    const [onSpeechError, setOnSpeechError] = useState<boolean>(false)
    const [onSpeechErrorMessage, setOnSpeechErrorMessaggge] = useState<string | null>(null)
    const [onSpeechTranscriptionData, setOnSpeechTranscriptionData] = useState<String>(String(''))

    // ------------------------- //

    const [onPaused, setOnPaused] = useState<boolean>(false) // A boolean value that returns true if the SpeechSynthesis object is in a paused state.
    const [onPeding, setOnPeding] = useState<boolean>(false) // A boolean value that returns true if the utterance queue contains as-yet-unspoken utterances.
    const [onSpeaking, setOnSpeaking] = useState<boolean>(false) // A boolean value that returns true if an utterance is currently in the process of being spoken — even if SpeechSynthesis is in a paused state.
    const [speakVoice, setSpeakVoice] = useState<SpeechSynthesisVoice | null>(null)

    // AUX Variables
    const SPEECH_RECOGNITION = useMemo(() => {
        // @ts-ignore
        const SR = (window.SpeechSynthesis || (('SpeechRecognition' in window) || ('webkitSpeechRecognition' in window))) ? new (window.SpeechRecognition || window.webkitSpeechRecognition)() : undefined

        if (!SR) return undefined;

        SR.lang = speechLang;
        SR.continuous = speechContinuous;
        SR.maxAlternatives = speechMaxAlternatives;
        SR.interimResults = speechInterimResults;

        return SR
    }, [speechLang, speechContinuous, speechInterimResults, speechMaxAlternatives])

    const SPEECH_SYNTHESIS = useMemo(() => {
        // @ts-ignore
        const SS = (window.SpeechSynthesisUtterance || (('SpeechSynthesisUtterance' in window) || ('webkitSpeechSynthesisUtterance' in window))) ? new (window.SpeechSynthesisUtterance || window.webkitSpeechSynthesisUtterance)() : undefined

        if (!SS) return undefined;

        SS.lang = speakLang;
        SS.pitch = speakPitch;
        SS.volume = speakVolume;
        SS.rate = speakRate;

        if (speakVoice) SS.voice = speakVoice

        return SS
    }, [speakLang, speakPitch, speakRate, speakVolume, speakVoice])


    /**
     * Init the recognition of voice to prompt
     * And set the cursor focus to prompt box
     */
    const handlerOnSetVoiceToPrompt = useCallback(() => {
        try {
            if (!SPEECH_RECOGNITION) throw new Error('');

            setOnSpeechTranscriptionData('');

            if (onSpeechRecognitionCallBackFn && typeof onSpeechRecognitionCallBackFn === 'function') onSpeechRecognitionCallBackFn();

            SPEECH_RECOGNITION.onresult = (event) => {
                try {
                    const TRANSCRIPTION_TO_PROMPT = Array.from(event.results).reduce((acp, np) => {
                        return acp.concat(np[0].transcript);
                    }, '')

                    setOnSpeechTranscriptionData(old => old.concat(TRANSCRIPTION_TO_PROMPT))
                } catch (error) {
                    // do anything
                }
            }

            SPEECH_RECOGNITION.onerror = (e) => {
                const errorType = e.error;
                let message = '';

                switch (errorType) {
                    case 'not-allowed':
                        message = 'Gravação de audio não foi permitida pelo usuário'
                        break;
                    case 'audio-capture':
                        message = 'Não foi possivel capturar o audio'
                        break;
                    case 'service-not-allowed':
                        message = 'Serviço de captura de audio indisponível'
                        break;
                    default:
                        message = 'Não foi possivel iniciar a gravação de voz'
                }

                setOnSpeechErrorMessaggge(message);
                setOnSpeechError(true);
            }

            SPEECH_RECOGNITION.onaudiostart = (_e) => {
                setOnSpeechErrorMessaggge(null)
                setOnSpeechError(false);
                setOnAudioStart(true);
            }

            SPEECH_RECOGNITION.onaudioend = (_e) => {
                setOnAudioStart(false);
            }

            SPEECH_RECOGNITION.onend = () => {
                setOnEnd(true)
            }

            SPEECH_RECOGNITION.onnomatch = () => {
                setSpeechOnNoMatch(true)
            }

            SPEECH_RECOGNITION.onsoundstart = () => {
                setSpeechOnSound(true)
            }

            SPEECH_RECOGNITION.onsoundend = () => {
                setSpeechOnSound(false)
            }

            SPEECH_RECOGNITION.onspeechstart = () => {
                setOnSpeech(true)
            }

            SPEECH_RECOGNITION.onspeechend = () => {
                setOnSpeech(false)
            }

            SPEECH_RECOGNITION.start();
        } catch (error: any) {
            setOnSpeechErrorMessaggge(error?.message || 'Have a error with speech');
            setOnSpeechError(true);

            if (onSpeechRecognitionRoolBackFn && typeof onSpeechRecognitionRoolBackFn === 'function') onSpeechRecognitionRoolBackFn();

        }
    }, [
        SPEECH_RECOGNITION, setOnAudioStart,
        setOnSpeechTranscriptionData, onSpeechRecognitionRoolBackFn,
        onSpeechRecognitionCallBackFn, setOnEnd,
        setSpeechOnNoMatch, setOnSpeechErrorMessaggge,
        setOnSpeechError
    ]
    )

    /**
     * Set to stop the recognition
     */
    const handlerOnSetStopVoiceToPrompt = useCallback(() => {
        try {
            if (!SPEECH_RECOGNITION) throw new Error('');

            setOnAudioStart(false);
            setOnSpeechTranscriptionData('');

            SPEECH_RECOGNITION.stop();
        } catch (error) {
            // do anything;
        }
    }, [SPEECH_RECOGNITION, setOnAudioStart, setOnSpeechTranscriptionData, setOnSpeechTranscriptionData])

    /**
     * Speak the text
     * @param textToSpeak The text target
     */
    const handlerOnSetSpeechToSpeak = useCallback((textToSpeak: string | null = null) => {
        try {

            if (!SPEECH_SYNTHESIS || (!textToSpeak || textToSpeak.trim() === '') || onSpeaking) return;

            SPEECH_SYNTHESIS.text = textToSpeak

            SPEECH_SYNTHESIS.onstart = () => {
                setOnSpeaking(true)
            }

            SPEECH_SYNTHESIS.onend = () => {
                setOnSpeaking(false)
            }

            SPEECH_SYNTHESIS.onpause = () => {
                setOnSpeaking(false)
            }

            SPEECH_SYNTHESIS.onresume = () => {
                setOnSpeaking(false)
            }

            SPEECH_SYNTHESIS.onerror = (e) => {
                console.table(e)
            }

            console.table({
                SPEECH_SYNTHESIS,
                textToSpeak,
                onSpeaking
            })


            window.speechSynthesis.speak(SPEECH_SYNTHESIS);
        } catch (error) {
            // do anything
            console.log(error)
        }
    }, [SPEECH_SYNTHESIS, onSpeaking])

    /**
     * Stop the actual speaking text
     * @param textToSpeak The text target
     */
    const handlerOnSetStopSpeechToSpeak = useCallback(() => {
        try {
            if (!SPEECH_SYNTHESIS || !onSpeaking) return;

            window.speechSynthesis.pause();
            window.speechSynthesis.resume();
            window.speechSynthesis.cancel();

            setOnSpeaking(false)
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS, onSpeaking, setOnSpeaking])

    const handlerOnSetSpeechToSpeakLang = useCallback((lang: string | null = null) => {
        try {

            if (!SPEECH_SYNTHESIS || (!lang || lang.trim() === '')) return;

            SPEECH_SYNTHESIS.lang = lang

            window.speechSynthesis.speak(SPEECH_SYNTHESIS);
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnSetSpeechToSpeakVoice = useCallback((voice: SpeechSynthesisVoice | null = null) => {
        try {

            if (!SPEECH_SYNTHESIS || (!voice || typeof voice !== 'object')) return;

            // SPEECH_SYNTHESIS.voice = voice
            setSpeakVoice(voice)
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnSetSpeechToSpeakPitch = useCallback((pitch: number | null = -1) => {
        try {

            if (!SPEECH_SYNTHESIS || (!pitch || pitch <= 0)) return;

            SPEECH_SYNTHESIS.pitch = pitch
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnSetSpeechToSpeakRate = useCallback((rate: number | null = -1) => {
        try {

            if (!SPEECH_SYNTHESIS || (!rate || rate <= 0)) return;

            SPEECH_SYNTHESIS.rate = rate
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnSetSpeechToSpeakVolume = useCallback((volume: number | null = -1) => {
        try {

            if (!SPEECH_SYNTHESIS || (!volume || volume <= 0)) return;

            SPEECH_SYNTHESIS.volume = volume
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnGetSpeechToSpeakVoices = useCallback(() => {
        try {

            if (!SPEECH_SYNTHESIS || !window?.speechSynthesis) return [];

            return window.speechSynthesis?.getVoices() || []
        } catch (error) {
            // do anything
        }
    }, [SPEECH_SYNTHESIS])

    const handlerOnSetSpeechCallback = useCallback(() => {
        try {

        } catch (error) {
            // do anything
        }
    }, [setOnSpeechTranscriptionData])

    const handlerOnSetClearSpeechTranscriptionData = useCallback(() => {
        try {
            setOnSpeechTranscriptionData('')
        } catch (error) {
            // do anything
        }
    }, [setOnSpeechTranscriptionData])


    return {
        onEnd,
        onError,
        onSpeech,
        onSpeaking,
        onSpeechError,
        onSpeechSound,
        onAudioStart,
        onSpeechNoMatch,
        onSpeechErrorMessage,
        onSpeechTranscriptionData,
        handlerOnSetVoiceToPrompt,
        handlerOnSetSpeechToSpeak,
        handlerOnSetStopSpeechToSpeak,
        handlerOnSetSpeechToSpeakLang,
        handlerOnSetStopVoiceToPrompt,
        handlerOnSetSpeechToSpeakRate,
        handlerOnSetSpeechToSpeakVoice,
        handlerOnSetSpeechToSpeakPitch,
        handlerOnGetSpeechToSpeakVoices,
        handlerOnSetSpeechToSpeakVolume,
        handlerOnSetClearSpeechTranscriptionData
    }

}