// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';

// Types
import { IStepData } from '../../types';
import { IProjectsProps } from './types';

// Partials
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { IMStates } from '~/models/States';
import { Image } from '~/pages/RealEstateProduct/Partials/Image';
import { MessageError } from '~/pages/RealEstateProduct/Partials/MessageError';
import api from '~/services/api';
import { Maps } from './Partials/Maps';

export function Project({ onDataStream, dataStep,enableDataUpdateButtonFunction }: IProjectsProps) {
  // UM VALOR ARBITRARIO PARA SEMPRE FORÇA UMA ATUALIZAÇÃO NO VALOR DO ESTADO
  const [updateMap, setUpdateMap] = useState(0)
  const [states, setStates] = useState<IMStates[]>([])

  // REF's
  const STATE_ELEMENT_REF = useRef<any>()

  useEffect(() => {
    if(states.length > 0) return;

    api.get<IMStates[]>(`${process.env.REACT_APP_API_URL}/builders/states`)
      .then(resolver => setStates(resolver.data))
      .catch(rejects => Swal.fire({
        text: 'Não foi possivel obter os estados',
        icon: 'warning'
      }))
  }, [])

  useEffect(() => {
    // if((!states || states.length > 0) || STATE_ELEMENT_REF.current?.value) return;

    console.table({enableDataUpdateButtonFunction})
    enableDataUpdateButtonFunction && enableDataUpdateButtonFunction(true, 2)
  },[])

  function handlerSetUpdateAddress(target: InputHTMLAttributes<HTMLInputElement>) {
    try {
      // @ts-ignore
      if (target.charCode === 13) {
        setUpdateMap(upm => upm + 1)
      }

    } catch (error) {
      // do anything
    }
  }

  function handlerSetPickStateID(stateID: number) {
    try {
      if(stateID <= 0) {
        enableDataUpdateButtonFunction && enableDataUpdateButtonFunction(true, 2)
        return;
      }

      onDataStream!({
            state_id: stateID
        },'2'
      )

      enableDataUpdateButtonFunction && enableDataUpdateButtonFunction(false, 2)
    } catch (error) {
      // do anything
    }
  }

  return (
    <div className="flex flex-col items-start justify-start gap-[1.875rem] w-full h-full max-sm:p-[1rem]">
      <p className="text-[#707070] font-[400] max-sm:w-full max-sm:text-center">
        Preencha as seguintes informações
      </p>

      <div className="w-full h-auto flex flex-col gap-[2.5rem] bg-white rounded-[0.937rem] p-[1.875rem] shadow-sm overflow-hidden">
        <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Nome do projeto
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="Meu projeto"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => onDataStream!({
                name: event.target.value || ''
              }, '2')}
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.name || ''}
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='name' />
          </div>
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Endereço do empreendimento
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="Aterrado do Imburo - Macaé"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => onDataStream!({
                localization: event.target.value || ''
              }, '2')}
              onKeyPress={(target) => handlerSetUpdateAddress(target)}
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.localization || ''}
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='localization' />
          </div>
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Estado do empreendimento
            </span>
            <select
              ref={STATE_ELEMENT_REF}
              name="state_id"
              id="state_id"
              className="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => handlerSetPickStateID(parseInt(event.target.value || '-1',10))}
            >
              <option value={-1} selected>Selecione um estado</option>

              {
                states.map(state => (<option value={state.id}>({state.sigla}){" "}{state.nome}</option>))
              }
            </select>
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='state_id' />
          </div>
        </div>
        <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Caso não tenha endereço exato, selecione-o no mapa
            </span>
            <Maps
              searchInput={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.localization || ''}
              onDoSearch={updateMap}
              onDataStream={onDataStream}
            />
          </div>
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Adicionar imagem de capa fictícia (opcional)
            </span>
            <Image
              callerID="2"
              callerKey="photo"
              callerPosition={-1}
              callerName={null}
              dataStep={dataStep}
              onDataStream={onDataStream}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
