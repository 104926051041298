import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import * as Yup from 'yup';

import api from '~/services/api';
import Toast from '~/utils/toast';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import getValidationErrors from '~/utils/getValidationsErrors';

import { useProfile } from '~/hooks/Profile';

import Textarea from '~/components/Textarea';
import { IMConstrutech } from '~/models/Construtech';
import AvatarConstrutech from '../Avatar';
import { Container } from './styles';

const FormConstrutech: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { setLoading, construtech, setConstrutech } = useProfile();
  const [errorInputCnpj, setErrorInputCnpj] = useState('');
  const [url, setUrl] = useState('');

  // FIXME - eslint-disable-next-line consistent-return
  const handleValidateCNPJ = useCallback((value: string) => {
    setErrorInputCnpj('');
    if (value.replace(/\D/g, '').length < 14) return false;
    cnpjValidator.isValid(value.replace(/\D/g, ''))
      ? setErrorInputCnpj('')
      : setErrorInputCnpj('CNPJ inválido');
  }, []);

  const handleSubmit = useCallback(
    async (data: IMConstrutech) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          razao_social: Yup.string().required('Razão social obrigatória'),
          nome_fantasia: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ obrigatório')
            .min(18, 'O CNPJ completo é obrigatório'),
          telefone: Yup.string()
            .required('Telefone obrigatório')
            .min(14, 'O telefone completo é obrigatório'),
          whatsapp: Yup.string().default(''),
          email: Yup.string().required('E-mail obrigatório'),
          about: Yup.string().default(''),
          bdi: Yup.number().required('BDI e obligatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          ...data,
          url,
        };

        if (errorInputCnpj.length === 0) {
          if (!construtech.id) {
            await api
              .post(
                `${process.env.REACT_APP_PREFIX_ROUTE}/construtech`,
                formData
              )
              .then((response) => {
                setConstrutech(response.data);
              });
          } else {
            await api
              .put(
                `${process.env.REACT_APP_PREFIX_ROUTE}/construtech/${construtech.id}`,
                formData
              )
              .then((response) => {
                setConstrutech(response.data);
              });
          }
        }
        setLoading(false);
        Toast.fire({
          icon: 'success',
          title: !construtech
            ? 'Cadastro realizado com sucesso!'
            : 'Atualização de cadastro realizado com sucesso!',
        });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [construtech, errorInputCnpj, setLoading, setConstrutech, url]
  );

  const handleFormatUrl = useCallback(
    (val: string) => {
      if (val.length <= 0) return;

      const newUrl = val
        .split(' ')
        .join('-')
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-');

      setUrl(newUrl);
    },
    [setUrl]
  );

  useEffect(() => {
    if (construtech.url) {
      handleFormatUrl(construtech.url);
    }
  }, [handleFormatUrl, construtech.url]);

  return (
    <Container>
      <Form
        className="row folder mt-4 mx-0"
        id="businessSubmit"
        ref={formRef}
        initialData={construtech}
        onSubmit={handleSubmit}
      >
        <div className="container px-4 py-2">
          <div className="row">
            <span className="small mt-3 text-center text-title">
              Dados do negócio
            </span>
          </div>
          <div className="row">
            <div className="col-2 order-1">
              {construtech?.id !== null && <AvatarConstrutech />}
            </div>
            <div className="col-10 order-2">
              <div className="row mx-sm-auto mx-lg-n2 mb-3">
                <div className="col-6 mt-5 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">Razão social</span>
                    <Input
                      className="input mt-1"
                      placeholder="Digite"
                      name="razao_social"
                    />
                  </label>
                </div>
                <div className="col-6 mt-5 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">Nome fantasia</span>
                    <Input
                      className="input mt-1"
                      placeholder="Digite"
                      name="nome_fantasia"
                    />
                  </label>
                </div>
                <div className="col-6 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">CNPJ</span>
                    <InputMask
                      className="input mt-1"
                      placeholder="00.000.000/0000-00"
                      kind="cnpj"
                      name="cnpj"
                      onChange={(e) => handleValidateCNPJ(e.target.value)}
                      value={construtech?.cnpj}
                    />
                    {formRef.current?.getErrors().cpf?.length === 28 && <br />}
                    {errorInputCnpj && (
                      <span className="small text-danger error">
                        {errorInputCnpj}
                      </span>
                    )}
                  </label>
                </div>
                <div className="col-6 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">
                      E-mail da empresa
                    </span>
                    <Input
                      className="input mt-1"
                      placeholder="Digite"
                      name="email"
                    />
                  </label>
                </div>
                <div className="col-6 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">Telefone</span>
                    <InputMask
                      className="input mt-1"
                      placeholder="(  )"
                      name="telefone"
                      kind="cel-phone"
                      value={construtech?.telefone}
                    />
                  </label>
                </div>
                <div className="col-6 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">BDI</span>
                    <Input
                      className="input"
                      type="number"
                      placeholder="00.00"
                      name="bdi"
                    />
                  </label>
                </div>
                <div className="col-6 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">
                      Seu site no Harvey
                    </span>
                    <Input
                      className="input"
                      placeholder="minha-empresa"
                      name="url"
                      onChange={(e) => handleFormatUrl(e.target.value)}
                    />
                  </label>
                  <span className="text-subtitle">{`${process.env.REACT_APP_URL}/construtech/${url}`}</span>
                </div>
                <div className="col-12 mb-3">
                  <label className="w-100">
                    <span className="small text-subtitle">
                      Sobre sua empresa
                    </span>
                    <Textarea
                      className="input-text-area mt-1"
                      placeholder="Digite"
                      name="about"
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <button
                    type="submit"
                    className="d-block btn-save fw-semibold border-0 mx-auto"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default FormConstrutech;
