import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMFileType } from '~/models/FileTypes';
import { IMParticipant } from '~/models/Participant';
import api from '~/services/api';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import ParticipantsName from '~/pages/ServiceOrderDashboard/Components/ParticipantsName';
import BoxDocumentGroups from '../BoxDocumentGroups';
import { Container, MembersNameBox } from './styles';

interface IParams {
  osId: string;
}

const CustomersDocuments: React.FC = () => {
  const paramsData = useParams<IParams>();
  const { serviceOrder, participants } = useServiceOrder();
  const [participantSelected, setParticipantSelected] = useState<
    IMParticipant | undefined
  >({} as IMParticipant | undefined);
  const [seeMore, setSeeMore] = useState(
    serviceOrder.status_id === 3 || serviceOrder.status_id === 4
  );
  const [fileTypes, setFileTypes] = useState<IMFileType[]>([]);
  const [loading, setLoading] = useState(false);

  // AUX Variables
  const BANK = serviceOrder.simulacao?.amortizacao?.amortizacao.toLocaleLowerCase() || ''
  const HAS_CAIXA_BANK = useMemo(() => BANK.includes('caixa'), [])
  const FILE_TYPE_TO_CAIXA_BANK = "Autorizacao pesquisa cadastral";

  useEffect(() => {
    setParticipantSelected(participants[0]);
  }, [participants]);

  const handleSetSelected = useCallback((participantData: IMParticipant) => {
    setParticipantSelected(participantData);
  }, []);

  const loadDocumentLists = useCallback(async () => {
    try {
      setLoading(true);
      const params = participantSelected?.type === 'spouse'
        ? { conjuge_id: participantSelected.id }
        : participantSelected?.type === 'participant'
          ? { participante_id: participantSelected?.id }
          : {};
      await api
        .get<IMFileType[]>(
          `builders/file-types/${serviceOrder.id || paramsData.osId}`,
          {
            params: {
              grupo: 1,
              ...params,
            },
          }
        )
        .then((response) => {
          setFileTypes(response.data);
        });
    } catch (err) {
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os tipos de documentos!',
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [participantSelected, serviceOrder.id, paramsData.osId]);

  useEffect(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  const handleUpdateFileTypes = useCallback(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  return (
    <div>
      <div className="mt-4">
        <b>Dados Clientes</b>
      </div>
      <Container className="px-2 mt-2 pb-2 d-flex flex-column">
        <div className="d-flex align-items-center w-full">
          <MembersNameBox className="col-10">
            {participants && (
              <>
                {participants.map((participant, index) => (
                  <ParticipantsName
                    key={(participant.type || '') + (participant.id || 0)}
                    participant={participant}
                    number={String(index + 1)}
                    onSelect={handleSetSelected}
                    borderSelected={
                      !!(
                        participantSelected
                        && participantSelected.type === participant.type
                        && participantSelected.id === participant.id
                      )
                    }
                  />
                ))}
              </>
            )}
          </MembersNameBox>
          <div className="col-2 d-flex justify-content-end me-4">
            {!seeMore ? (
              <button
                type="button"
                onClick={() => setSeeMore(true)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver mais <MdKeyboardArrowDown />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setSeeMore(false)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver menos <MdKeyboardArrowUp />
              </button>
            )}
          </div>
        </div>
        {seeMore && (
          <div>            
            {fileTypes.map((fileType) => {
              if(fileType?.nomenclatura === FILE_TYPE_TO_CAIXA_BANK && HAS_CAIXA_BANK ) {
                return  (
                  <div
                    key={fileType.id}
                    className="mt-4"
                  >
                    <BoxDocumentGroups
                      fileType={fileType}
                      participant={participantSelected}
                      onChangeFiles={handleUpdateFileTypes}
                    />
                  </div>
                )
              }else if(fileType?.nomenclatura !== FILE_TYPE_TO_CAIXA_BANK) {
                return (
                  <div
                    key={fileType.id}
                    className="mt-4"
                  >
                    <BoxDocumentGroups
                      fileType={fileType}
                      participant={participantSelected}
                      onChangeFiles={handleUpdateFileTypes}
                    />
                  </div>
                )
              }else {
                return (<></>)
              }
            })}
          </div>
        )}
      </Container>
      <Loading active={loading} />
    </div>
  );
};

export default CustomersDocuments;
