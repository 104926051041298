import ChartJS from "chart.js/auto";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Icons
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { FiPaperclip } from "react-icons/fi";
import checkGreenIcon from "~/assets/icons/check-green.svg";
import closeRedIcon from "~/assets/icons/close-red.svg";
import repClipsIcon from "~/assets/icons/rep-clips.svg";
import repMapIcon from "~/assets/icons/rep-map.svg";
import mcfBuildHhouse from "~/assets/img/mcf-build-house.png";
import mcfAgreeBuild from "~/assets/img/mfc-agree-build.png";
import mcfBoxInsta from "~/assets/img/mfc-box-insta.png";
import mfcBoxMail from "~/assets/img/mfc-box-mail.png";
import mfcBoxWhatsUp from "~/assets/img/mfc-box-whatsup.png";

// Types
import { IChatCompletions, IUserPrompt, TPromptRoles } from "~/services/openAI/types";
import { IStudyProps, TSlidersID } from "./types";

// Modesl
import { IMBatche } from "~/models/Batche";
import { IMRealStateProduct } from "~/models/RealEstateProduct";
import { IMRealEstateProductHomeOptions } from "~/models/RealEstateProductHomeOption";
import { IMRealEstateProductsHouse } from "~/models/RealEstateProductHouse";

// Hooks
import { useAuthContext } from "~/hooks/contexts/Auth";

// Services
import api from "~/services/api";
import { AIGPT } from "~/services/openAI";

// Styles
import { debounce } from "~/utils/debounce";
import './styles.css';

export function Study(_props: IStudyProps) {
    const { user } = useAuthContext()    
    const [slider, setSlider] = useState<TSlidersID>(1)
    const [batches, setBatches] = useState<IMBatche[]>([])
    const [batchesCompletions, setBatchesCompletions] = useState<IChatCompletions | null>(null)
    const [locationCompletions, setLocationCompletions] = useState<IChatCompletions | null>(null)
    const [clientCompletions, setClientCompletions] = useState<IChatCompletions | null>(null)

    // AUX Variables
    const PARAMS = useParams<{id:string}>()
    const USER_PROMPT = (user?.name || `${new Date().getTime()}`).split(' ').join('-').toLocaleLowerCase()
    const PROMPT_ORIGIN = 'rep-study-views'
    const REP_PROJECT: IMRealStateProduct | null = JSON.parse(sessionStorage.getItem('REP@PROJECT') || '{}') || null
    const REP_BUILD_DATA: {
        houseAmount: number
        idealArea: number
        idealAreaBuild: number,
        AMOUNT_BY_METERS: string,
        VGV: {
            [key: number]: {
                vgv: number;
                house_area: number;
                terrain_area: number;
            }[];
        }
    } | null = JSON.parse(sessionStorage.getItem('REP@BUILD_DATA') || '{}') || null
   
    const REP_HOUSES:IMRealEstateProductsHouse[] | null = JSON.parse(sessionStorage.getItem('REP@HOUSES') || '{}') || null
    
    const PROJECT_MAD = Math.round(REP_PROJECT?.built_houses && REP_PROJECT?.quantity_terrains ? ((REP_PROJECT?.built_houses || 1) / (REP_PROJECT?.quantity_terrains || 1)) * 100 : 0)

    const AI_STATEMENT = useMemo(() => new AIGPT(user,null, [], PROMPT_ORIGIN,'gpt-3.5-turbo'),[user])
    
    const VGV_RANGE: {quantity: string, label: string, labelRaw: number}[] = useMemo(() => {
        const VGV_RANGE: any[] = []
        Object.keys(REP_BUILD_DATA?.VGV || {}).forEach(key => {
            VGV_RANGE.push({
                label: Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(key as unknown as number),
                labelRaw: key as unknown as number,
                quantity: `${REP_BUILD_DATA?.VGV[key as unknown as number].length || 0}`
            })
        })

        // for(let i=0; i < D.length; i++) {
                
        //     if(i + 1 > D.length) break;

        //     for(let j=i+1; j <= D.length - 1; j++ ){

        //         if(D[i].labelRaw > D[j].labelRaw ){
        //             const k = D[i]
        //             const w = D[j]

        //             D[j] = k
        //             D[i] = w
        //         }
        //     }
        // } 

        return VGV_RANGE
    }, [])
    
    const VGV_IN_TEXT = VGV_RANGE.map((d, i) => {
        if((i + 1) <= VGV_RANGE.length && (d.quantity > '1' && (VGV_RANGE[i + 1] && d.quantity >= VGV_RANGE[i + 1]?.quantity) || d.quantity === VGV_RANGE[i - 1]?.quantity)) return d;
    }).map(d => d?.label || '').filter(d => d.trim() !== '').join(', ')
    
    const VGV_RANGE_FIRST_AMOUNT = VGV_IN_TEXT.split(', ')[0]
    const VGV_RANGE_LAST_AMOUNT = VGV_IN_TEXT.split(', ')[VGV_IN_TEXT.split(', ').length - 1]

    const VGV_RANGE_FIRST_AMOUNT_RAW = parseFloat(VGV_IN_TEXT.split(', ')[0].split('R$ ')[0])
    const VGV_RANGE_LAST_AMOUNT_RAW = parseFloat(VGV_IN_TEXT.split(', ')[VGV_IN_TEXT.split(', ').length - 1].split('R$ ')[0])

    const HOUSES_TOTAL = useMemo(() => REP_HOUSES?.length || 0,[REP_HOUSES])
    const HOUSE_AREA_BUILD = useMemo(() => Math.round(REP_HOUSES?.reduce((d,v) => v.house_area + d, 0) || 0),[REP_HOUSES])
    const HOUSE_AREA = useMemo(() => REP_HOUSES?.reduce((d,v) => v.terrain_area + d, 0) || 0,[REP_HOUSES])
    const X = useMemo(() => {
        const BY = 100
        const AREA_BY = Math.round(HOUSE_AREA_BUILD / BY)
        const AREAS_LIST: number[] = []
        let AREA_ACCUMULETED = 0

        Array(AREA_BY).fill(null).forEach((d,i) => {
            // if(AREA_ACCUMULETED === HOUSE_AREA_BUILD) return;
            
            AREAS_LIST.push(AREA_ACCUMULETED)

            AREA_ACCUMULETED += BY

        })

        return AREAS_LIST
    }, [HOUSE_AREA_BUILD])
    const Y = useMemo(() => {
        const BY = 100
        const AREA_BY = Math.round(HOUSE_AREA / BY)
        const AREAS_LIST: number[] = []
        let AREA_ACCUMULETED = 0

        Array(AREA_BY).fill(null).forEach((d,i) => {
            // if(AREA_ACCUMULETED === HOUSE_AREA_BUILD) return;
            
            AREAS_LIST.push(AREA_ACCUMULETED)

            AREA_ACCUMULETED += BY

        })

        return AREAS_LIST
    }, [HOUSE_AREA_BUILD])
    const HOUSES_IN_AREA_BUILD = useMemo(() => {
        const T: {
            [index: string]: {
                houses: ((IMRealEstateProductsHouse | null))[]
                count: number
                rangeRaw: number[]
            }
        } = {}

        for(let i=0; i < X.length; i++) {
            const NEXT = i + 1
            let HOUSES_COUNT = 0

            if(!X?.[NEXT]) break;

            for(let g =0; g <= HOUSES_TOTAL; g++) {
                const HOUSE_AREA_BUILD = REP_HOUSES?.[g]?.house_area || 0                

                if(HOUSE_AREA_BUILD > X[i] && HOUSE_AREA_BUILD <= X[NEXT]) {
                    HOUSES_COUNT++

                    if(!T[`${X[i]}_${X[NEXT]}`]) {
                        T[`${X[i]}_${X[NEXT]}`] = {
                            houses: [REP_HOUSES?.[g] || null],
                            count: HOUSES_COUNT,
                            rangeRaw: [X[i],X[NEXT]]
                        }
                    }else {
                        T[`${X[i]}_${X[NEXT]}`]['count'] = HOUSES_COUNT
                        T[`${X[i]}_${X[NEXT]}`]['houses'].push(REP_HOUSES?.[g] || null)
                    }
                }
            }
        }

        // console.log(X)
        // console.log(T)

        return T
    }, [X,HOUSES_TOTAL])
    const HOUSES_IN_AREA = useMemo(() => {
        const T: {
            [index: string]: {
                houses: ((IMRealEstateProductsHouse | null))[]
                count: number
                rangeRaw: number[]
            }
        } = {}

        for(let i=0; i < Y.length; i++) {
            const NEXT = i + 1
            let HOUSES_COUNT = 0

            if(!X?.[NEXT]) break;

            for(let g =0; g <= HOUSES_TOTAL; g++) {
                const HOUSE_AREA = REP_HOUSES?.[g]?.terrain_area || 0

                if(HOUSE_AREA > X[i] && HOUSE_AREA <= X[NEXT]) {
                    HOUSES_COUNT++

                    if(!T[`${X[i]}_${X[NEXT]}`]) {
                        T[`${X[i]}_${X[NEXT]}`] = {
                            houses: [REP_HOUSES?.[g] || null],
                            count: HOUSES_COUNT,
                            rangeRaw: [X[i],X[NEXT]]
                        }
                    }else {
                        T[`${X[i]}_${X[NEXT]}`]['count'] = HOUSES_COUNT
                        T[`${X[i]}_${X[NEXT]}`]['houses'].push(REP_HOUSES?.[g] || null)
                    }
                }
            }
        }

        // console.log(X)
        // console.log(T)

        return T
    }, [Y,HOUSES_TOTAL])

    const OPTIONS = useMemo(() => {
        if (!REP_PROJECT || REP_PROJECT!.houses!.length <= 0) return;

        const optionsInMount: IMRealEstateProductHomeOptions[] = []

        for (let i = 0; i < REP_PROJECT!.houses!.length; i++) {
            // @ts-ignore
            for (let j = 0; j < REP_PROJECT!.houses[i]!.home_options!.length; j++) {
                // @ts-ignore
                let homeOption = REP_PROJECT!.houses[i]!.home_options[j];

                if (optionsInMount.find(optionMount => optionMount.option === homeOption.option || optionMount.id === homeOption.id)) continue;

                optionsInMount.push(homeOption);
            }
        }        

        return optionsInMount
    },[REP_PROJECT])


    // console.log(HOUSES_IN_AREA_BUILD)

    async function handlerSetToExternal(dataToStorage: IUserPrompt | undefined = undefined, chatCompletions: IChatCompletions, repID = -1 ) {
        try {
            const P_TARGET = 0

            if(dataToStorage && chatCompletions) {
                // FILTER BY CONTEXT TYPE AND FORCE ONLY ARRAY OF LENGHT 1
                dataToStorage['prompts'] = [
                    (dataToStorage?.prompts.filter(p => p.origin.toString() === PROMPT_ORIGIN))?.[0] || []
                ]

                // It maybe that the local store time is different from the runtime for these functions
                // That is why it is necessary to perform a forced update on the array of choices
                dataToStorage['choices'] = [...chatCompletions.choices,...dataToStorage['choices']]
            }

            await AI_STATEMENT.setOnExternalChatData(dataToStorage,P_TARGET ,repID)
        } catch (error) {
            // do anything
        }
    }

    function getBatches() {
        try {
            api.get<IMBatche[]>(`builders/real-estate-products/${PARAMS?.id || -1}/terrains`).then((resolver) => {
                if (resolver.status !== 200) return;

                setBatches(resolver.data)

                getBatchesCompletions(resolver.data)
            })            
        } catch (error) {
            // do anything            
        }
    }

    async function getBatchesCompletions(batcheList: IMBatche[] =[]) {
        try {
            if(!batcheList || batcheList.length <= 0) return;
            if(batchesCompletions) return;

            const PROMPTS = [
                // @ts-ignore
                ...batcheList.map((b, index) => ({
                    name: USER_PROMPT,
                    role: 'system' as TPromptRoles,
                    content: `${index + 1}ª - Lote (${b.name}), com uma área de ${b.area}m² de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(b.amount)} por ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(b.square_meter)}/m²`
                })),
                {
                    name: USER_PROMPT,
                    // @ts-ignore
                    role: 'system',
                    content: 'Do(s) lote(s) informado(s) acima, e considerando o preço de lote por m², qual demonstra ser a melhor opção? Informe o nome do lote e explique por que ele é a melhor opção'
                }
            ] 

            AI_STATEMENT.set('localyType',PROMPT_ORIGIN) 
            
            // @ts-ignore
            AI_STATEMENT.set('prompts', PROMPTS)

            console.log(PROMPTS,batcheList)

            // @ts-ignore
            const COMPLETIONS_RESPONSE = await AI_STATEMENT.onChatStream(PROMPTS, null, () => ({}), () => ({}),false)
            const USER_DATA_STORAGED = AI_STATEMENT.getChatCompletionsStoraged()?.[user?.unique_id || '']            

            await AI_STATEMENT.setChatStreamDataToStorage(COMPLETIONS_RESPONSE)
            await handlerSetToExternal(USER_DATA_STORAGED, COMPLETIONS_RESPONSE, REP_PROJECT?.id)

            setBatchesCompletions(COMPLETIONS_RESPONSE)
        } catch (error) {
            // do anything
        }
    }

    async function getLocationCompletions() {
        try {
            if(locationCompletions) return;

            const PROMPTS = [
                {
                    name: USER_PROMPT,
                    // @ts-ignore
                    role: 'system' as TPromptRoles,
                    content: `Qual é a capital do estado do endereço: ${REP_PROJECT?.localization || ''}, e qual é o tempo e distancia de viagem`
                }
            ] 

            AI_STATEMENT.set('localyType','rep-study-views-location') 
                        
            AI_STATEMENT.set('prompts', PROMPTS)

            console.log(PROMPTS,locationCompletions)

            // @ts-ignore
            const COMPLETIONS_RESPONSE = await AI_STATEMENT.onChatStream(PROMPTS, null, () => ({}), () => ({}),false)
            const USER_DATA_STORAGED = AI_STATEMENT.getChatCompletionsStoraged()?.[user?.unique_id || '']                                        
            
            await AI_STATEMENT.setChatStreamDataToStorage(COMPLETIONS_RESPONSE)            
            await handlerSetToExternal(USER_DATA_STORAGED, COMPLETIONS_RESPONSE, REP_PROJECT?.id)

            setLocationCompletions(COMPLETIONS_RESPONSE)
        } catch (error) {
            // do anything
        }
    }

    async function getClientCompletions() {
        try {            
            if(clientCompletions) return;

            const PROMPTS = [
                {
                    name: USER_PROMPT,
                    // @ts-ignore
                    role: 'system',
                    content: `Quais são os requisitos mais importantes para quem procura um imóvel de lazer em ${REP_PROJECT?.localization || ''}?, faça um breve resumo`
                }
            ] 

            AI_STATEMENT.set('localyType',PROMPT_ORIGIN + "-" + "client-profile") 
            
            // @ts-ignore
            AI_STATEMENT.set('prompts', PROMPTS)            

            // @ts-ignore
            const COMPLETIONS_RESPONSE = await AI_STATEMENT.onChatStream(PROMPTS, null, () => ({}), () => ({}),false)

            const USER_DATA_STORAGED = AI_STATEMENT.getChatCompletionsStoraged()?.[user?.unique_id || '']
            const P_TARGET = 0
            
            if(USER_DATA_STORAGED) {
                // FILTER BY CONTEXT TYPE AND FORCE ONLY ARRAY OF LENGHT 1
                USER_DATA_STORAGED['prompts'] = [
                    (USER_DATA_STORAGED?.prompts.filter(p => p.origin.toString() === PROMPT_ORIGIN))?.[0] || []
                ]

                // It may be that the local store time is different from the runtime for these functions
                // That is why it is necessary to perform a forced update on the array of choices
                USER_DATA_STORAGED['choices'] = [...COMPLETIONS_RESPONSE.choices,...USER_DATA_STORAGED['choices']]
            }

            // console.table({d: USER_DATA_STORAGED?.['prompts']})

            await AI_STATEMENT.setChatStreamDataToStorage(COMPLETIONS_RESPONSE)
            await AI_STATEMENT.setOnExternalChatData(USER_DATA_STORAGED,P_TARGET ,REP_PROJECT?.id)

            setClientCompletions(COMPLETIONS_RESPONSE)
        } catch (error) {
            // do anything
        }
    }

    const handlerOnKeyboardEvent = useCallback((code:string = '', actualSlider = -1) => {
        try {
            if(!code) throw new Error('');

            switch(code) {
                case 'ArrowDown':
                    actualSlider > 1 && setSlider( actualSlider - 1 as TSlidersID)
                    break;
                case 'ArrowLeft':
                    actualSlider > 1 && setSlider( actualSlider - 1 as TSlidersID)
                    break;
                case 'ArrowUp':                     
                    (actualSlider >= 1 && actualSlider < 16) && setSlider(actualSlider + 1 as TSlidersID)
                    break;
                case 'ArrowRight':
                    console.log(actualSlider < 16,actualSlider)
                    actualSlider < 16 && setSlider(actualSlider + 1 as TSlidersID)
                    break;
                default:
                    // do anything
            }
        } catch (error) {
            // do anythig
        }
    },[slider])

    // MAKE THE CHARTS RENDER
    useEffect(() => {
        const CHART_CONTAINER_PROPERTY_VGV = document.querySelector("#property-amount") as HTMLCanvasElement
        const CHART_CONTAINER_PROPERTY_AREA = document.querySelector("#property-area") as HTMLCanvasElement
        const CHART_CONTAINER_PROPERTY_AREA_BUILD = document.querySelector("#property-area-build") as HTMLCanvasElement
        const CHART_CONTAINER_PROPERTY_AMOUNT_AREA = document.querySelector("#property-amount-area") as HTMLCanvasElement

        if(!CHART_CONTAINER_PROPERTY_AREA || !CHART_CONTAINER_PROPERTY_VGV) return;

        const CHART_STATEMENT_PROPERTY_VGV = new ChartJS(CHART_CONTAINER_PROPERTY_VGV, {
            type: 'bar',        
            data: {
                // labels: Object.keys(REP_BUILD_DATA?.VGV || {}).map(k => k),
                datasets: [{
                    label: 'QUANTIDADES DE UNIDADES',
                    backgroundColor: '#079269',
                    borderRadius: 11.5,
                    hoverBackgroundColor: '#a3d3c5',
                    data: VGV_RANGE
                }]
            },
            options: {
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'quantity',
                }
            }
        })

        const CHART_STATEMENT_PROPERTY_AREA = new ChartJS(CHART_CONTAINER_PROPERTY_AREA, {
            type: 'bar',        
            data: {
                 labels: REP_HOUSES?.map((d,i) => d.name),
                datasets: [{
                    label: 'ÀREA CONSTRUIDA (m²)',
                    backgroundColor: '#079269',
                    borderRadius: 11.5,
                    hoverBackgroundColor: '#a3d3c5',
                    data: REP_HOUSES?.map((d,i) => d.house_area) || []
                }]
            },
            options: {
                parsing: {
                    // xAxisKey: 'label',
                    yAxisKey: 'quantity',                    
                }
            }
        })

        const CHART_STATEMENT_PROPERTY_AREA_BUILD = new ChartJS(CHART_CONTAINER_PROPERTY_AREA_BUILD, {
            type: 'bar',        
            data: {
                 labels: REP_HOUSES?.map((d,i) => `${d.name}`),
                datasets: [{
                    label: 'ÀREA DO TERRENO (m²)',
                    backgroundColor: '#079269',
                    borderRadius: 11.5,
                    hoverBackgroundColor: '#a3d3c5',                    
                    data: REP_HOUSES?.map((d,i) => d.terrain_area) || []
                }]
            },
            options: {
               plugins: {
                title: {
                    text: 'Casa'
                },
               }
            }
        })

        const CHART_STATEMENT_PROPERTY_AMOUNT_AREA = new ChartJS(CHART_CONTAINER_PROPERTY_AMOUNT_AREA, {
            data: {
                labels: REP_HOUSES?.map((d,i) => `${d.name}`),
                datasets: [{
                    type: 'bar',
                    label: 'AT (m²)',
                    backgroundColor: '#079269',
                    borderRadius: 11.5,
                    hoverBackgroundColor: '#afebda',                    
                    data: REP_HOUSES?.map((d,i) => d.terrain_area) || []
                },
                {
                    type: 'bar',
                    label: 'AC (m²)',
                    backgroundColor: '#a3d3c5',
                    borderRadius: 11.5,
                    hoverBackgroundColor: '#daf1ea',                    
                    data: REP_HOUSES?.map((d,i) => d.house_area) || []
                },
            ]
            },
            options: {
               plugins: {
                title: {
                    text: 'Casa'
                },
               }
            }
        })

        return () => {
            CHART_STATEMENT_PROPERTY_VGV.destroy();
            CHART_STATEMENT_PROPERTY_AREA.destroy();
            CHART_STATEMENT_PROPERTY_AREA_BUILD.destroy();
            CHART_STATEMENT_PROPERTY_AMOUNT_AREA.destroy();
        }
    },[])

    useEffect(() => {
        getBatches()
        getClientCompletions()
        getLocationCompletions()
    }, [])    

    useEffect(() => {
         if(document) {
            document.addEventListener('keydown',( event ) => debounce(1,handlerOnKeyboardEvent, event.code,slider))
        }

        return () => {
            document.removeEventListener('keydown',( event ) => debounce(1,handlerOnKeyboardEvent, event.code,slider))
        }
    },[slider])

    return (
        <div className="ml-[106px] h-screen relative overflow-hidden" 
            style={{
                width: 'calc(100vw - 106px)',                
            }}
        >
            <span
                role="button" 
                onClick={() => setSlider(old => (old === 1 ? old : old - 1) as TSlidersID)} 
                data-first={slider === 1} 
                id="left-bar-navigation"
                className="
                    absolute
                    w-1/6
                    h-screen
                    px-4
                    z-[-1]
                    pointer-events-none
                    top-0
                    left-0
                    flex flex-row 
                    flex-nowrap 
                    items-center justify-center
                    gap-4
                    cursor-default
                    select-none
                    data-[first=true]:bg-black/0                    
                    data-[first=false]:hover:bg-black/20
                    data-[first=false]:cursor-pointer
                    data-[first=false]:z-50
                    data-[first=false]:pointer-events-auto
                    transition-colors
                    duration-[0.15s]
                "
            >
                <BsChevronLeft
                    data-first={slider === 1}
                    size={64} 
                    className="chevron-left 
                        text-white/20
                        transition-colors
                        duration-[0.12s] hidden
                    " 
                />
            </span>

            <span
                role="button"
                onClick={() => setSlider(old => (old + 1) as TSlidersID)}
                data-last={slider === 16} 
                id="right-bar-navigation"
                className="absolute
                    w-1/6
                    h-screen
                    px-4
                    z-[-1]
                    pointer-events-none
                    cursor-default
                    top-0
                    right-0
                    flex flex-row
                    flex-nowrap
                    items-center justify-center
                    gap-4
                    data-[last=true]:bg-black/0
                    data-[last=false]:hover:bg-black/20
                    data-[last=false]:cursor-pointer
                    data-[last=false]:z-[999]
                    data-[last=false]:pointer-events-auto
                    transition-colors
                    duration-[0.15s]
                "
            >
                 <BsChevronRight
                    data-last={slider === 16} 
                    size={64} 
                    className="chevron-right text-white hidden" 
                />
            </span>

            <span data-first={slider === 1} className="absolute px-4 w-full 
                    z-[999] h-auto bottom-5
                    flex flex-row flex-nowrap items-center justify-center 
                    gap-2
                "
            >
                {
                    Array(16).fill(null).map((_d,i) => (
                        <span
                            data-slided={(i + 1) === slider}
                            title={`${i + 1}ª Slider`}
                            role="button"
                            key={i}
                            className="
                                w-[18px] h-[18px]
                                
                                data-[slided=false]:opacity-50
                                data-[slided=true]:w-[32px]
                                data-[slided=true]:h-[32px]
                                data-[slided=false]:hover:opacity-20
                                data-[slided=false]:hover:w-[32px]
                                data-[slided=false]:hover:h-[32px]
                                data-[slided=false]:hover:text-xs

                                rounded-full
                                border-[1px]
                                border-slate-200 bg-slate-100 
                                font-bold text-black text-[10px]
                                flex flex-row 
                                flex-flex-nowrap 
                                items-center justify-center
                                
                                select-none
                                
                                transition-all
                                duration-[0.15s]
                            "
                            onClick={() => debounce(50, setSlider, (i + 1))}
                        >
                            {i + 1}
                        </span>
                    ))
                }
            </span>

            <div data-infocusID={slider === 1} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-row items-start gap-0">
                <div className="w-[40%] h-full relative flex flex-col justify-between p-16 bg-[#04583f]">
                    <span className="absolute rounded-[32px]
                            bg-[#067554] w-[170px] h-[170px] flex flex-col items-center justify-between
                            gap-4
                            top-[150px]
                            p-4
                            text-white
                            cursor-default
                            select-none
                        "
                        style={{
                            left: 'calc(100% - 85px)'
                        }}
                    >
                        <span className="font-bold text-3xl">2023</span>
                        <span className="font-medium text-center text-xl">Estudo de mercado</span>
                    </span>
                    <span className="text-white font-semibold">MCF | Sócios</span>
                    <hgroup className="mt-16 upper font-semibold text-6xl text-white space-y-8">
                        <h1 className="text-xl font-meidum">INCORPORAÇÃO</h1>
                        <h3 className="font-bold max-w-[75%] uppercase">
                            {REP_PROJECT?.standard.standard || 'N/A'}
                        </h3>
                    </hgroup>
                    <span className="w-full text-center font-semibold text-3xl text-white">
                        {REP_PROJECT?.name || 'N/A'}
                    </span>
                </div>
                <div className="flex-1 h-full bg-gray-300 bg-rep-study-main bg-center bg-no-repeat bg-cover ">
                    <span className="w-full h-full bg-[#04583f11] flex"></span>
                </div>
            </div>

            <div data-infocusID={slider === 2} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                <span className="w-full h-auto flex flex-row items-center justify-between gap-4">
                    <span className="text-5xl">Localização</span>
                    <span className="text-2xl">MCF | Sócios</span>
                </span>

                <div className="w-[40%] flex-1 max-h-[50%] self-center bg-[#d5d5d5] overflow-hidden">
                </div>

                <div className="w-full h-auto flex flex-row justify-center flex-nowrap gap-4 relative">
                    <span className="flex-1 flex flex-col gap-2 max-w-[40%] p-8 rounded-xl bg-[#e1eeea]">
                        <span className="font-semibold">Distância até a capital</span>
                        
                        <span className="mb-3">
                            {
                                REP_PROJECT?.localization || 'N/A'
                            }
                        </span>

                        <ul className="flex flex-col max-h-32 overflow-y-auto text-[#737373] font-medium gap-2">
                            {
                                locationCompletions?.choices[0].message.content?.split('\n').map((d,index) => (<li className="list-type-dot">
                                    {d}
                                </li>))
                            }
                        </ul>

                    </span>
                    <span className="flex-1 flex flex-col gap-4 p-8 rounded-xl bg-[#e1eeea]">
                        <span className="font-bold text-xl">Maturidade do Condominio</span>

                        <span className="w-full flex flex-row items-center flex-nowrap gap-4">
                            <span className="w-full h-8 rounded-2xl bg-white flex flex-row items-center overflow-hidden">
                                <span data-percent={PROJECT_MAD} className="h-full rounded-r-[20px] bg-[#087958]" style={{width: `${PROJECT_MAD}%`}}></span>
                            </span>
                            <span className="font-bold text-xl">{PROJECT_MAD}%</span>
                        </span>
                    </span>
                </div>
            </div>

            <div data-infocusID={slider === 3} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col gap-16 pl-16 bg-white">
                <div className="flex-1 w-full max-h-[45%] flex flex-row flex-nowrap">
                     <span className="flex-1 h-auto flex flex-col justify-between gap-4 pt-16 pb-12">
                        <span className="w-full h-auto flex flex-col  gap-4">
                            <span className="text-5xl">Características do</span>
                            <span className="text-5xl font-bold">Projeto</span>
                        </span>

                        <span className="font-normal text-2xl">Atualmente {REP_PROJECT?.name || 'N/A'} conta com:</span>
                    </span>
                    <span className="flex-1 rounded-bl-[70px] bg-slate-200" style={{
                        // @ts-ignore
                        backgroundImage: `url("${process.env.REACT_APP_API_URL}/builders/real-estate-products/${REP_PROJECT?.id || -1}/photo")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                    </span>
                </div>

                <span className="h-64 w-full flex flex-row flex-wrap items-start gap-x-4 gap-y-2 pr-16 self-center overflow-y-auto">
                    {
                       REP_PROJECT?.condominium_options?.map((coption, index) => (
                            <span key={index} className="min-w-full md:min-w-[15%] flex  flex-row items-center justify-start gap-4">
                                <span className="w-10 h-10 rounded-md bg-[#079269]"></span>
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-normal">
                                    {coption.option}
                                </span>
                            </span>
                        ))
                    }
                </span>
            </div>

            <div data-infocusID={slider === 4} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-row justify-between gap-4 pl-16 bg-white">
                <div className="w-[60%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Oportunidade  </span>
                        <span className="text-5xl font-bold">Lançamento Riveira Golf</span>
                    </span>

                    <span className="font-normal text-2xl -mt-60 text-left md:max-w-[70%]">
                        Quadra 11, 12, 16 e 17 já estão consolidados com casas à venda e casas em construção.                        
                        Módulo 15 é o mais novo  e possui um potencial enorme, pois foi projetado com ruas mais largas, 
                        lotes maiores e tendo ainda como  destaque alguns terrenos com garagem  náutica. Características que contribuem para
                        este módulo ser um dos de mais alto padrão.
                    </span>

                    <span className="flex flex-col gap-12">
                        <span className="w-full flex flex-row items-center gap-4">
                            <span className="flex w-12 h-12">
                                <img src={repMapIcon} alt="Duas tábuas apontando em sentindos contrário a outra" className="max-w-full h-auto" />
                            </span>

                            <span className="max-w-[50%] text-[#079269] font-medium text-xl">
                                Clique aqui para ver o Mapa <br></br> (Master Plan de Riveira)
                            </span>
                        </span>
                    </span>
                </div>

                <div className="flex-1 h-full bg-slate-200 rounded-tl-[46px] rounded-bl-[46px]">

                </div>
            </div>

            <div data-infocusID={slider === 5} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-row flex-nowrap justify-between gap-4 pl-16 bg-white">
                <div className="w-[75%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Perfil do <span className="text-5xl font-bold">Cliente</span> </span>
                    </span>

                    <ul className="font-normal text-2xl  text-left md:max-w-[70%] flex flex-col gap-4">
                        <li className="relative after:absolute after:w-2 after:h-2 after:bg-black after:rounded-full after:-left-4 after:top-4">{clientCompletions?.choices[0].message.content}</li>
                        <li className="relative after:absolute after:w-2 after:h-2 after:bg-black after:rounded-full after:-left-4 after:top-4">Renda média dos compradores são de {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(REP_PROJECT?.average_income_value || 0)}</li>
                        <li className="relative after:absolute after:w-2 after:h-2 after:bg-black after:rounded-full after:-left-4 after:top-4">A maioria dos compradores são de São Paulo*.</li>
                        <li className="relative after:absolute after:w-2 after:h-2 after:bg-black after:rounded-full after:-left-4 after:top-4">A maioria das aquisições são à vista ou parcelado sem a necessidade de financiamento*.</li>
                        <li className="relative after:absolute after:w-2 after:h-2 after:bg-black after:rounded-full after:-left-4 after:top-4">Faixa etária média de {REP_PROJECT?.average_age_residents} anos.</li>
                    </ul>

                    <span className="w-full h-48 px-24 flex flex-row flex-nowrap items-center justify-between rounded-2xl gap-4 bg-[#cde9e1] relative">                        
                        <span className="flex items-center justify-center w-24 h-24 bg-[#079269] rounded-3xl absolute left-0 -translate-x-1/2 top-1/2 -translate-y-1/2">
                            <FaUserAlt size={24}  className="text-white"/>
                        </span>

                        <span className="min-w-[25%] flex flex-col gap-2 font-medium text-xl border-r-[2px] border-[#008fd6] pr-16 last-of-type:border-none">
                            <span className="text-gray-400">Pagamento</span>
                            <span>À vista ou parcelado*</span>
                        </span>

                        <span className="min-w-[25%] flex flex-col gap-2 font-medium text-xl border-r-[2px] border-[#008fd6] pr-16 last-of-type:border-none">
                            <span className="text-gray-400">Renda média </span>
                            <span>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(REP_PROJECT?.average_income_value || 0)}</span>
                        </span>

                        <span className="min-w-[25%] flex flex-col gap-2 font-medium text-xl border-r-[2px] border-[#008fd6] pr-16 last-of-type:border-none">
                            <span className="text-gray-400">Faixa etária</span>
                            <span>{REP_PROJECT?.average_age_residents} anos</span>
                        </span>
                    </span>                    
                </div>

                <div className="flex-1 h-full bg-slate-200 rounded-tl-[46px] rounded-bl-[46px]">

                </div>
            </div>

            <div data-infocusID={slider === 6} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full min-h-screen h-auto flex flex-col gap-4 justify-between p-8 bg-white">
                <div className="w-full flex flex-col justify-between gap-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Valor do imóvel perfomardo predominante</span>
                    </span>

                </div>

                <div className="flex-1 w-full overflow-auto flex flex-row items-center justify-center">
                    <canvas id="property-amount"></canvas>
                </div>
                
                <div className="w-full  flex items-center justify-center m-auto">
                    <span className="font-normal text-gray-400 text-xl max-w-[70%] flex gap-2">
                        O Valor de venda do imóvel perfomado- Ticket médio- predominante é de  
                        <span>
                            {VGV_IN_TEXT.split(', ')[0]}
                        </span>
                        <span>
                            á
                        </span>
                        <span>
                            {VGV_IN_TEXT.split(', ')[VGV_IN_TEXT.split(', ').length - 1]}
                        </span>
                    </span>
                    
                </div>
            </div>

            <div data-infocusID={slider === 7} className="absolute opacity-100 -left-[100%] data-[inFocusID=true]:opacity-0  data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full min-h-screen h-auto flex flex-col gap-4 p-8 bg-white">
                <div className="w-full flex flex-col justify-between gap-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Área construída predominante</span>
                    </span>

                </div>

                <div className="w-full overflow-auto flex flex-row items-center justify-center gap-4"
                    style={
                        {
                            height: 'calc(100vh - 172px)'
                        }
                    }
                >
                    {
                        Object.entries(HOUSES_IN_AREA_BUILD).map((d, _i) => (
                            <span 
                                key={d[0]}
                                className="min-w-16 h-full 
                                    flex flex-col justify-end 
                                    gap-4 pb-28 bg-slate-100
                                    border-[1px] border-slate-400
                                    px-4 pt-4 rounded-md shadow-md
                                "
                            >
                                <span
                                    data-maxCount={d[1].count >= 100}
                                    data-count={d[1].count}
                                    className="
                                        area-build-house
                                        w-full rounded-xl 
                                        bg-[#38A88E]
                                        p-2
                                        hover:bg-[#2b8a74]
                                        transition-all
                                        duration-[0.15s]
                                        flex flex-col items-center justify-center
                                        text-slate-100 font-semibold
                                    "
                                    style={{
                                        height: `${d[1].count >= 100 ? '100' : d[1].count}%`
                                    }}
                                >
                                    {d[1].count}
                                </span>
                                <span className="font-semibold text-slate-800">
                                    {d[1].rangeRaw.join(' - ')}
                                </span>
                            </span>
                        ))
                    }
                </div>

                <div className="flex-1 w-full overflow-auto flex flex-row items-center justify-center hidden">
                    <canvas id="property-area"></canvas>
                </div>
            </div>

            <div data-infocusID={slider === 8} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full min-h-screen h-auto flex flex-col gap-4 p-8 bg-white">
                <div className="w-full flex flex-col justify-between gap-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Área do terreno predominante</span>
                    </span>
                </div>
                
                <div className="w-full overflow-auto flex flex-row items-center justify-center gap-4"
                    style={
                        {
                            height: 'calc(100vh - 172px)'
                        }
                    }
                >
                    {
                        Object.entries(HOUSES_IN_AREA).map((d, _i) => (
                            <span 
                                key={d[0]}
                                className="min-w-16 h-full 
                                    flex flex-col justify-end 
                                    gap-4 pb-28 bg-slate-100
                                    border-[1px] border-slate-400
                                    px-4 pt-4 rounded-md shadow-md
                                "
                            >
                                <span
                                    data-maxCount={d[1].count >= 100}
                                    data-count={d[1].count}
                                    className="
                                        area-build-house
                                        w-full rounded-xl 
                                        bg-[#38A88E]
                                        p-2
                                        hover:bg-[#2b8a74]
                                        transition-all
                                        duration-[0.15s]
                                        flex flex-col items-center justify-center
                                        text-slate-100 font-semibold
                                    "
                                    style={{
                                        height: `${d[1].count >= 100 ? '100' : d[1].count}%`
                                    }}
                                >
                                    {d[1].count}
                                </span>
                                <span className="font-semibold text-slate-800">
                                    {d[1].rangeRaw.join(' - ')}
                                </span>
                            </span>
                        ))
                    }
                </div>

                <div className="flex-1 w-full overflow-auto flex flex-row items-center justify-center hidden">
                    <canvas id="property-area-build"></canvas>
                </div>
            </div>

            <div data-infocusID={slider === 9} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full min-h-screen h-auto flex flex-col gap-4 p-8 bg-white">
                <div className="w-full flex flex-col justify-between gap-16">
                    <span className="w-full h-auto flex flex-col  gap-4">
                        <span className="text-5xl">Valores dos imóveis x Área construída x Área do terreno</span>
                    </span>

                </div>

                <div className="flex-1 w-full overflow-auto flex flex-row items-center justify-center">
                    <canvas id="property-amount-area"></canvas>
                </div>
            </div>

            <div data-infocusID={slider === 10}
                className="absolute 
                    opacity-100 -left-full
                    data-[inFocusID=true]:opacity-0  
                    data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] 
                    last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 
                    p-16
                "
            >
                <div className="w-[60%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col gap-4">
                        <span className="text-5xl">Resumo do produto</span>
                        <span className="text-5xl font-bold">imobiliário ideal e indicador</span>
                    </span>
                </div>
                <span className="w-full flex-1 flex flex-row flex-wrap gap-12">
                    <span className="min-w-[25%] max-w-[50%] h-24 flex flex-row gap-2">
                        <span className="w-1/4 h-full bg-[#079269] text-xl font-bold text-white flex flex-row items-center justify-center">
                            F
                        </span>
                        <span className="flex-1 flex flex-col items-start gap-4">
                            <span className="text-md font-semibold">Faixa de venda do imóvel performado</span>
                            <span className="text-gray-400 font-normal flex gap-2">
                                Entre
                                <span>
                                    {VGV_IN_TEXT.split(', ')[0]}
                                </span>
                                <span>
                                    á
                                </span>
                                <span>
                                    {VGV_IN_TEXT.split(', ')[2]}
                                </span>
                            </span>
                        </span>
                    </span>
                    
                    <span className="w-[25%] h-24 flex flex-row gap-2">
                        <span className="w-1/4 h-full bg-[#079269] text-xl font-bold text-white flex flex-row items-center justify-center">
                            V
                        </span>
                        <span className="flex-1 flex flex-col items-start gap-4">
                            <span className="text-md font-semibold">Valor médio do terreno/m²</span>
                            <span className="text-gray-400 font-normal flex gap-2">
                                DADOS DE TERRENO DO(S) LOTE(S)
                            </span>
                        </span>
                    </span>

                    <span className="w-[25%] h-24 flex flex-row gap-2">
                        <span className="w-1/4 h-full bg-[#079269] text-xl font-bold text-white flex flex-row items-center justify-center">
                            V
                        </span>
                        <span className="flex-1 flex flex-col items-start gap-4">
                            <span className="text-md font-semibold">Valor médio de venda do imóvel por m²</span>
                            <span className="text-gray-400 font-normal flex gap-2">
                                {
                                    REP_BUILD_DATA?.AMOUNT_BY_METERS || ''
                                }/m²
                            </span>
                        </span>
                    </span>

                    <span className="w-[25%] h-24 flex flex-row gap-2">
                        <span className="w-1/4 h-full bg-[#079269] text-xl font-bold text-white flex flex-row items-center justify-center">
                            A
                        </span>
                        <span className="flex-1 flex flex-col items-start gap-4">
                            <span className="text-md font-semibold">Área do terreno</span>
                            <span className="text-gray-400 font-normal flex gap-2">
                                {
                                    REP_BUILD_DATA?.idealArea || ''
                                }/m²
                            </span>
                        </span>
                    </span>

                    <span className="w-[25%] h-24 flex flex-row gap-2">
                        <span className="w-1/4 h-full bg-[#079269] text-xl font-bold text-white flex flex-row items-center justify-center">
                            A
                        </span>
                        <span className="flex-1 flex flex-col items-start gap-4">
                            <span className="text-md font-semibold">Área construída</span>
                            <span className="text-gray-400 font-normal flex gap-2">
                                {
                                    REP_BUILD_DATA?.idealAreaBuild || ''
                                }/m²
                            </span>
                        </span>
                    </span>
                </span>
            </div>

            <div data-infocusID={slider === 11} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                <div className="w-[60%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col gap-4">
                        <span className="text-5xl">Lotes disponíveis até {REP_BUILD_DATA?.idealAreaBuild}m² </span>
                    </span>
                </div>
                <span className="w-full flex-1 flex flex-col gap-0">
                    <span className="w-full h-12 flex flex-row flex-nowrap items-center justify-center">
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">Módulo</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Quadra/Lote</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Área (m²)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Valor total</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">R$/m²</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Frente(m)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Lateral(m)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Fundo(m)</span>
                    </span>                    
                    <span className="w-full flex-1 flex flex-col mt-2">
                        {
                            batches.map(batche => (
                                <span key={batche.id} data-idealbatch={batche.area <= (REP_BUILD_DATA?.idealArea || 0)} className="w-full h-auto flex flex-row flex-nowrap data-[idealbatch=true]:bg-[#9cd3c3] hover:bg-[#9cd3c3]">
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.name
                                        }
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.batch
                                        }
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.area
                                        }m²
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            Intl.NumberFormat("pt-BR", {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(batche.amount)
                                        }
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            Intl.NumberFormat("pt-BR", {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(batche.square_meter)
                                        }/m²
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.front
                                        }m²
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.side
                                        }m²
                                    </span>
                                    <span className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.bottom
                                        }m²
                                    </span>
                                </span>
                            ))
                        }
                    </span>                    
                </span>

                <Link to='' className="w-full cursor-pointer flex flex-row items-center justify-center gap-4 text-[#249f7b] font-semibold text-xl">
                    <span className="flex w-6 h-6">
                        <img src={repClipsIcon} alt="Um icone de clips"  className="max-w-full h-auto"/>
                    </span>
                    Clique aqui para ver as tabelas de valores
                </Link>
            </div>

            <div data-infocusID={slider === 12} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                <div className="w-[60%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col gap-4">
                        <span className="text-5xl text-black">Indicadores </span>
                        <span className="text-xl text-gray-500">
                            Amostra: Imóvel perfomado faixa {VGV_RANGE_FIRST_AMOUNT} á {VGV_RANGE_LAST_AMOUNT} e Faixa de terreno até 
                            {" "}
                            {REP_BUILD_DATA?.idealAreaBuild}m² 
                        </span>
                    </span>
                </div>
                <span className="w-full flex-1 flex flex-col gap-0">
                    <span className="w-full h-12 flex flex-row flex-nowrap items-center justify-center">
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">Módulo</span>                        
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Área (m²)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Valor total</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">R$/m²</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Anúncio</span>
                    </span>                    
                    <span className="w-full flex-1 flex flex-col mt-[14px]">
                        {                            
                            batches.map((batche,index) => (
                                <span key={batche.id} data-idealbatch={batche.area <= (REP_BUILD_DATA?.idealArea || 0) && (batche.amount >= VGV_RANGE_FIRST_AMOUNT_RAW || batche.amount <= VGV_RANGE_LAST_AMOUNT_RAW )} className="w-full h-auto flex flex-row flex-nowrap data-[idealbatch=true]:bg-[#9cd3c3] hover:bg-[#9cd3c3]">
                                    <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.name
                                        }
                                    </span>
                                    <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            batche.area
                                        }m²
                                    </span>
                                    <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            Intl.NumberFormat("pt-BR", {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(batche.amount)
                                        }
                                    </span>
                                    <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        {
                                            Intl.NumberFormat("pt-BR", {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(batche.square_meter)
                                        }/m²
                                    </span>
                                    <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                        Clique aqui
                                    </span>
                                </span>
                            ))
                        }
                    </span>                    
                </span>
            </div>

            <div data-infocusID={slider === 13} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                <div className="w-full flex-1 flex flex-row flex-nowrap overflow-x-auto gap-4">
                    <div className="flex-1 min-w-[15%] flex flex-col gap-4">
                        <span className="w-full flex flex-row items-center gap-2 font-semibold text-2xl">
                            <span className="p-1 pl-2 bg-green-500 flex flex-row items-center rounded-xl">
                                <span className="ml-3">Casa</span>
                            </span>
                            <span>Ideal</span>
                        </span>
                        <span className="w-full h-24 bg-gray-200">
                            <img
                                src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/houses/${1}/photo`}
                                alt="Imagem da casa"
                                className="max-w-full h-auto object-cover"
                                loading="lazy"
                            />
                        </span>
                        <span className="w-full flex flex-col items-center justify-center gap-2 font-semibold">
                            <span>
                                VGV {Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(REP_BUILD_DATA?.houseAmount || 0)
                                }
                            </span>
                            <span>
                                AT {REP_BUILD_DATA?.idealArea}m²
                            </span>
                            <span>
                                AC {REP_BUILD_DATA?.idealAreaBuild}m²
                            </span>
                        </span>
                        <span className="w-full flex-1 flex flex-col gap-2">
                            {
                                // @ts-ignore
                                OPTIONS.map((hOption, hIndex) => {
                                    return <span key={hIndex}
                                        className="w-full h-[2.187rem] odd:bg-[#F1F3F2] text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]"
                                    >
                                        {hOption.option}
                                    </span>
                                })
                            }
                        </span>
                    </div>                                       
                    {
                        REP_PROJECT && REP_PROJECT?.houses?.map((house, index) => (
                            <div key={index} className="flex-1 min-w-[15%] flex flex-col gap-4">
                                <span className="w-full flex flex-row items-center gap-2 font-semibold text-2xl">
                                    <span className="p-1 pl-2 bg-green-500 flex flex-row items-center rounded-xl">
                                        <span className="ml-3">Casa</span>
                                    </span>
                                    <span>{index + 1}</span>
                                </span>
                                <span className="w-full h-24 bg-gray-200">
                                    <img src="#" alt="no image" />
                                </span>
                                <span className="w-full flex flex-col items-center justify-center gap-2 font-semibold">
                                    <span>
                                        VGV {Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(house.vgv)
                                        }
                                    </span>
                                    <span>
                                        AT {house?.terrain_area || 0}m²
                                    </span>
                                    <span>
                                        AC {house?.house_area || 0}m²
                                    </span>
                                </span>
                                <span className="w-full flex-1 flex flex-col gap-2">
                                    {
                                        // @ts-ignore
                                        OPTIONS.map((hOption, hIndex) => {
                                            const houseAlreadyOption = house && house?.home_options?.find((cOption) => cOption.option === hOption.option && cOption.id === hOption.id)

                                            if (houseAlreadyOption) return (
                                                <span key={hIndex}
                                                    className="w-full h-[2.187rem] odd:bg-[#F1F3F2] text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]"
                                                >
                                                    {hOption.option}
                                                </span>
                                            )
                                        })
                                    }
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div data-infocusID={slider === 14} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                 <div className="w-full md:min-h-[43.75rem] max-sm:min-h-[10rem] h-auto flex flex-row flex-nowrap">
                    <div className="flex flex-col md:min-w-[19rem] md:max-w-[19rem] max-sm:flex-1 max-sm:min-w-[10rem] h-auto pt-[5.937rem] gap-[0.5rem] ">
                            <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070] bg-[#cde9e1] flex flex-row flex-nowrap items-center justify-start">Súites</span>
                            <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070] bg-[#cde9e1] flex flex-row flex-nowrap items-center justify-start">Banheiros</span>
                            <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070] bg-[#cde9e1] flex flex-row flex-nowrap items-center justify-start">Vagas de garagem</span>

                            {
                                OPTIONS?.map((cOption, index) => (
                                    <span key={index}
                                        className={`´w-full h-[2.187rem]
                                            pl-[0.625rem] rounded-l-[5.437rem]
                                            text-[0.90rem] font-medium
                                            text-[#707070] bg-[#cde9e1]
                                            flex flex-row flex-nowrap
                                            items-center justify-start
                                        `}
                                    >
                                        {cOption.option}
                                    </span>
                                ))
                            }
                    </div>
                    <div className="w-full flex flex-col overflow-hidden rounded-tr-[0.937rem] rounded-tl-[0.937rem]">
                        <div className="w-full max-w-[100%] h-auto flex flex-row flex-nowrap overflow-x-auto">
                            <div className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] group/lastItem gap-[0.5rem] relative">
                                <div className="flex flex-col
                                    items-center justify-between
                                    rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                    overflow-hidden
                                    w-[12.25rem] h-[5.437rem]
                                "
                                >
                                     <span className="w-full flex flex-row items-center gap-2 font-semibold text-2xl">
                                        <span className="p-1 pl-2 bg-green-500 flex flex-row items-center rounded-xl">
                                            <span className="ml-3">Casa</span>
                                        </span>
                                        <span>Ideal</span>
                                    </span>
                                </div>

                                <span className={`w-full h-[2.187rem]
                                        bg-[#cde9e1]
                                        text-[0.90rem] font-medium
                                        text-[#707070]
                                        flex flex-row flex-nowrap
                                        items-center justify-start
                                        pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                    `}
                                >
                                    {
                                            REP_PROJECT && REP_PROJECT?.houses?.reduce((a,b) => b.bedrooms > a ? b.bedrooms : a, 0)
                                    }                                                
                                </span>

                                <span  className={`w-full h-[2.187rem] text-[0.90rem] font-medium
                                    text-[#707070]
                                    bg-[#cde9e1]
                                    flex flex-row flex-nowrap
                                    items-center justify-start
                                    pl-[29%]
                                    `}
                                >
                                    {
                                        REP_PROJECT && REP_PROJECT?.houses?.reduce((a,b) => b.bathrooms > a ? b.bathrooms : a, 0)
                                    }                                                
                                </span>

                                <span className={`w-full h-[2.187rem]  text-[0.90rem] font-medium bg-[#cde9e1]
                                        text-[#707070]
                                        flex flex-row flex-nowrap
                                        items-center justify-start
                                        pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                    `}
                                >
                                    {
                                        REP_PROJECT && REP_PROJECT?.houses?.reduce((a,b) => b.parking_spaces > a ? b.parking_spaces : a, 0)
                                    }
                                </span>

                                {
                                    // @ts-ignore
                                    OPTIONS.map((hOption, hIndex) => {
                                        return (
                                            <span key={hIndex}
                                                className={`´w-full h-[2.187rem] bg-[#cde9e1] text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]`}
                                            >
                                                <img src={checkGreenIcon} alt="" className="!max-width-[1rem] h-auto" />
                                            </span>
                                        )
                                    })                        
                                }
                            </div>
                            {
                                REP_PROJECT && REP_PROJECT?.houses?.map((house, index) => (
                                    <div key={index} className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] group/lastItem gap-[0.5rem] relative">
                                        <div className="flex flex-col
                                            items-center justify-between
                                            rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                            overflow-hidden
                                            w-[12.25rem] h-[5.437rem]
                                        "
                                        >
                                            <span className="w-full flex flex-row items-center gap-2 font-semibold text-2xl">
                                                <span className="p-1 pl-2 bg-gray-200 flex flex-row items-center rounded-xl">
                                                    <span className="ml-3">Casa</span>
                                                </span>
                                                <span>{index + 1}</span>
                                            </span>
                                        </div>

                                        <span key={index}
                                            className={`´w-full h-[2.187rem]
                                                bg-[#cde9e1]
                                                text-[0.90rem] font-medium
                                                text-[#707070]
                                                flex flex-row flex-nowrap
                                                items-center justify-start
                                                pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                            `}
                                        >
                                            {house.bedrooms}
                                        </span>

                                        <span key={index}
                                            className={`´w-full h-[2.187rem] text-[0.90rem] font-medium
                                                bg-[#cde9e1]
                                                text-[#707070]
                                                flex flex-row flex-nowrap
                                                items-center justify-start
                                                group-last/lastItem:rounded-r-[1rem]
                                                pl-[29%]
                                    `}
                                        >
                                            {house.bathrooms}
                                        </span>

                                        <span key={index}
                                            className={`´w-full h-[2.187rem]  text-[0.90rem] font-medium bg-[#cde9e1]
                                                text-[#707070]
                                                flex flex-row flex-nowrap
                                                items-center justify-start
                                                pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                    `}
                                        >
                                            {house.parking_spaces}
                                        </span>

                                        {
                                            // @ts-ignore
                                            OPTIONS.map((hOption, hIndex) => {
                                                const houseAlreadyOption = house && house?.home_options?.find((cOption) => cOption.option === hOption.option && cOption.id === hOption.id)

                                                if (houseAlreadyOption) return (
                                                    <span key={hIndex}
                                                        className={`´w-full h-[2.187rem] bg-[#cde9e1] text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]`}
                                                    >
                                                        <img src={checkGreenIcon} alt="" className="!max-width-[1rem] h-auto" />
                                                    </span>
                                                )

                                                if (!houseAlreadyOption) return (
                                                    <span key={hIndex}
                                                        className={`´w-full h-[2.187rem] bg-[#cde9e1] text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]`}
                                                    >
                                                        <img src={closeRedIcon} alt="" className="!max-width-[1rem] h-auto" />
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                 </div>
            </div>

            <div data-infocusID={slider === 15} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-white">
                <div className="w-[60%] flex flex-col justify-between gap-16 py-16">
                    <span className="w-full h-auto flex flex-col gap-4">
                        <span className="text-5xl">Sugestão de escolha do terreno (Por I.A)</span>
                    </span>

                    <span className="text-gris-200 text-xl flex flex-col gap-3">
                        {
                            batchesCompletions?.choices[0].message.content?.split('\n\n').map((d,i) => (<span key={i} className="flex">{d}</span>))
                        }
                    </span>
                </div>

                <span className="w-full flex-1 flex flex-row flex-nowrap items-center justify-between gap-4">
                    <span className="flex flex-1 max-w-[70%] h-full">
                        <span className="w-full h-full flex flex-col gap-0">
                            <span className="w-full h-12 flex flex-row flex-nowrap items-center justify-center">
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">Módulo</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Quadra/Lote</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Área (m²)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Valor total</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">R$/m²</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Frente(m)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Lateral(m)</span>
                        <span className="flex flex-nowrap items-center justify-center p-6 bg-[#cde9e1] flex-1 font-semibold border-t-[2px] border-b-[2px] border-r-[2px] border-black">Fundo(m)</span>
                            </span>
                            <span className="w-full flex-1 flex flex-col mt-2">
                                {
                                    batches.map((batche, index) => (
                                        <span key={batche.id} data-idealbatch={batchesCompletions?.choices[0].message.content?.includes(batche.name)} className="w-full h-auto flex flex-row flex-nowrap data-[idealbatch=true]:bg-[#9cd3c3] hover:bg-[#9cd3c3]">
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.name
                                                }
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.batch
                                                }
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.area
                                                }m²
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    Intl.NumberFormat("pt-BR", {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    }).format(batche.amount)
                                                }
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    Intl.NumberFormat("pt-BR", {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    }).format(batche.square_meter)
                                                }/m²
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.front
                                                }m²
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.side
                                                }m²
                                            </span>
                                            <span data-first={index === 0} className="max-w-[50%] flex flex-nowrap items-center justify-center p-6 flex-1 font-semibold data-[first=true]:border-t-[0px] first-of-type:border-l-[2px]  border-b-[2px] border-t-[2px] border-r-[2px] border-black">
                                                {
                                                    batche.bottom
                                                }m²
                                            </span>
                                        </span>
                                    ))
                                }
                            </span>
                        </span>
                    </span>
                    {/* <span className="flex flex-1 max-w-[50%] h-full bg-red-500"></span>                         */}
                </span>

                <span className="text-gray-400 text-xl text-center">
                    Os valores informados acima foram gerados pelo <span className="font-bold text-[#079269] cursor-default select-none">Harvey I.A</span>
                </span>
            </div>

            <div data-infocusID={slider === 16} className="absolute opacity-100  -left-[100%] data-[inFocusID=true]:opacity-0   data-[inFocusID=true]:left-0 transition-all duration-[0.38s] border-[1px] border-r-[#000] last-of-type:border-none w-full h-screen flex flex-col justify-between gap-4 p-16 bg-[#079269]">
                <div className="w-full flex flex-row flex-nowrap items-center justify-end gap-4">
                   <span className="flex w-44">
                        <img src={mcfAgreeBuild} alt="Icone generico" className="max-w-full h-auto" />
                   </span>
                   <span className="flex w-16">
                        <img src={mcfBuildHhouse} alt="Icone com silueta de uma casa" className="max-w-full h-auto" />
                   </span>
                </div>

                <div className="w-full flex flex-col  gap-8">
                    <span className="flex w-full flex-row flex-nowrap items-center gap-4">
                        <span className="w-12">                            
                            <img src={mfcBoxMail} alt="Icone generico" className="max-w-full h-auto" />
                        </span>
                        <span className="text-white font-semibold text-xl">
                            {
                                user?.construtech.email || 'N/A'
                            }
                        </span>
                    </span>
                    <span className="flex w-full flex-row flex-nowrap items-center gap-4">
                        <span className="w-12">                            
                            <img src={mcfBoxInsta} alt="Icone generico" className="max-w-full h-auto" />
                        </span>
                        <span className="text-white font-semibold text-xl">
                            {
                                user?.engenheiro?.social_works?.instagram || 'N/A'
                            }
                        </span>
                    </span>
                    <span className="flex w-full flex-row flex-nowrap items-center gap-4">
                        <span className="w-12">                            
                            <img src={mfcBoxWhatsUp} alt="Icone generico" className="max-w-full h-auto" />
                        </span>
                        <span className="text-white font-semibold text-xl">
                            {
                                user?.construtech.telefone || 'N/A'
                            }
                        </span>
                    </span>
                    <span className="flex w-full flex-row flex-nowrap items-center gap-4">
                        <FiPaperclip size={32} className="text-white"  />
                        <span className="text-white font-semibold text-xl ml-4">
                           {
                                user?.construtech?.site || 'N/A'
                            }
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}